<div class="dropdown-nested" ngbDropdown #drp="ngbDropdown" placement="bottom-start" (openChange)="acc.collapseAll()">
	<button type="button" class="dropdown-nested-toggle" [ngClass]="{ 'item-selected': ngModel }" ngbDropdownToggle
		[disabled]="disabled">
		<span *ngIf="!ngModel; else elseBlock">{{placeholder}}</span>
		<ng-template #elseBlock>
			<img [src]="imageUrl(ngModel.ringType)" alt="ring type" />
			<span>{{(ngModel.ringType | ringTypeLabel:isFoot) + " - " + ngModel.description}}</span>
		</ng-template>
	</button>
	<div ngbDropdownMenu aria-labelledby="dropdownForm1">
		<ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
			<ngb-panel [id]="type" *ngFor="let type of ringTypeList">
				<ng-template ngbPanelHeader>
					<button type="button" ngbPanelToggle>
						<img [src]="imageUrl(type)" alt="ring type" />
						<span>{{type | ringTypeLabel:isFoot}}</span>
					</button>
				</ng-template>
				<ng-template ngbPanelContent>
					<button type="button" *ngFor="let ring of ringList?.[type]" (click)="select(ring);drp.close();">
						{{ ring.description }}
					</button>
				</ng-template>
			</ngb-panel>
		</ngb-accordion>
	</div>
</div>