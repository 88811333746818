import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenderEnum, LanguageService, SearchFields, SearchItems, SearchSortOptions } from '../../core';
import { ListService, UserService } from '../../services';
import { BaseComponent } from '../../shared';
import { SortEvent, SortableHeaderComponent } from '../sortable-header';


/**
* Patient table component
*/
@Component({
	selector: 'patient-table',
	templateUrl: './patient-table.component.html'
})
export class PatientTableComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	patientList: SearchItems[] = [];
	collectionSize: number = 0;
	activeColumn: string;
	isTlhexUser: boolean;

	readonly fields = SearchFields;

	@ViewChildren(SortableHeaderComponent) private readonly _headers: QueryList<SortableHeaderComponent>;
	private _searchResultSubscription$: Subscription;

	constructor(
		private readonly langSrv: LanguageService,
		private readonly listSrv: ListService,
		private readonly router: Router,
		private readonly userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.listSrv.resetSearchResult();
		this.initTable();
		this._searchResultSubscription$ = this.listSrv.getSearchResult().subscribe(list => {
			this.collectionSize = list?.count ?? 0;
			this.patientList = list?.items ?? [];
		});
		this.userSrv.isTlhex().subscribe(val => this.isTlhexUser = val);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const sortOrder: SearchSortOptions = this.listSrv.getSortOrder();
			if (sortOrder) {
				this._headers.find(header => header.name == sortOrder.name).direction = sortOrder.desc ? 'desc' : 'asc';
				this.activeColumn = sortOrder.name;
			}
		});
	}

	private initTable(): void {
		this.listSrv.resetPatientSearch();
	}

	/** 
	 * Get css class according to gender.
	*/
	genderClass(gender: GenderEnum): string {
		switch (gender) {
			case GenderEnum.FEMALE: return 'f';
			case GenderEnum.MALE: return 'm';
			case GenderEnum.OTHER: return 'o';
			default: return '';
		}
	}

	/**
	* Update column sort order and sort list
	*/
	onSort({ column, direction }: SortEvent): void {
		// resetting other headers
		this._headers.forEach(header => header.name !== column ? header.reset() : null);
		// sorting
		this.activeColumn = direction === 'asc' || direction === 'desc' ? column : null;
		this.listSrv.sort(column, direction);
	}

	/**
	* Navigate to patient edit page
	*/
	editPatient(patientGuid: string): void {
		this.router.navigate(["/patient/edit"], { queryParams: { patientGuid: patientGuid } });
	}


	ngOnDestroy() {
		this._searchResultSubscription$?.unsubscribe();
		this.listSrv.resetSearchResult();
	}

}
