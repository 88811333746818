import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { RingSize, RingTypeEnum } from '../../core';


/**
 * Component to select tlhex rings.
 */
@Component({
	selector: 'ring-selection',
	templateUrl: './ring-selection.component.html'
})
export class RingSelectionComponent {

	@Input() set list(items: RingSize[]) {
		if (items && items.length > 0) {
			const orderedList = _.orderBy(items, ['ringType', 'description'], ['asc', 'asc']);
			this.ringList = _.groupBy(orderedList, 'ringType');
		}
	}

	@Input() isFoot: boolean = false;
	@Input() disabled: boolean = false;
	@Input() placeholder: string = 'Select Ring';
	@Input() ngModel: RingSize;
	@Output() ngModelChange: EventEmitter<RingSize> = new EventEmitter<RingSize>();

	ringList: Map<RingTypeEnum, RingSize[]>;
	readonly ringTypeList: RingTypeEnum[] = [
		RingTypeEnum.FullRing,
		RingTypeEnum.OpenAnteriorRing,
		RingTypeEnum.OpenMedialRing,
		RingTypeEnum.OpenPosteriorRing,
		RingTypeEnum.FootPlate,
		RingTypeEnum.FullRing58Plus38
	];

	/**
	 * Get url of image ring type. 
	 */
	imageUrl(ringType: RingTypeEnum): string {
		switch (ringType) {
			case RingTypeEnum.FootPlate: return 'assets/images/Rings/FootPlate.png';
			case RingTypeEnum.FullRing: return 'assets/images/Rings/FullRing.png';
			case RingTypeEnum.FullRing58Plus38: return 'assets/images/Rings/FullRing(58plus38).png';
			case RingTypeEnum.OpenAnteriorRing: return 'assets/images/Rings/58OpenAnteriorlyRing.png';
			case RingTypeEnum.OpenMedialRing: return 'assets/images/Rings/58OpenMediallyRing.png';
			case RingTypeEnum.OpenPosteriorRing: return 'assets/images/Rings/58OpenPosteriorlyRing.png';
			default: return '';
		}
	}

	/**
	 * Update selected ring.
	 */
	select(item: RingSize): void {
		this.ngModel = item;
		this.ngModelChange.emit(item);
	}

}
