import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, first, map, Observable, tap } from 'rxjs';
import { ApiResponse, ApiResponseStatusCode, IntegrationRepository, LoaderService, MountingParameters, RingSize, TlhexRepository } from '../core';


/**
 * This service handles tlhex data.
 */
@Injectable()
export class TlhexService {

	private _ringSizes: BehaviorSubject<RingSize[]> = new BehaviorSubject<RingSize[]>([]);

	constructor(
		private tlhexRepo: TlhexRepository,
		private loaderSrv: LoaderService,
		private integrationRepo: IntegrationRepository
	) { }

	/**
	 * Create postop plan from preop.
	 */
	createPostopPlan(planId: string, userId: string): Observable<string> {
		this.loaderSrv.show();
		return this.integrationRepo.createPostopPlan(planId, userId).pipe(
			finalize(() => this.loaderSrv.hide()),
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	* Get ring list.
	*/
	getRingSizes(): Observable<RingSize[]> {
		if (this._ringSizes.value?.length) {
			return this._ringSizes.asObservable().pipe(first());
		}
		return this.tlhexRepo.getRingSizes().pipe(
			map(res => this.handleApiResponse(res)),
			tap(list => this._ringSizes.next(list))
		);
	}

	/**
	 * Get mounting parameters.
	 * @param caseId Case Guid.
	 * @param userId User Guid.
	 * @returns Mounting parameters data.
	 */
	getMountingParameters(caseId: string, userId: string): Observable<MountingParameters> {
		return this.tlhexRepo.getMountingParameters(caseId, userId).pipe(
			map(res => this.handleApiResponse(res)),
		)
	}


	private handleApiResponse<T>(response: ApiResponse<T>) {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error('Generic error');
		}
	}
}