import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService, PlanTypeEnum } from '../../core';


/**
 * Pipe to convert Plan Type enum to display value.
 */
@Pipe({
	name: 'planTypeLabel'
})
export class PlanTypeLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(planType: PlanTypeEnum): string {
		switch (planType) {
			case PlanTypeEnum.Deformity: return this.langSrv.labels.PLAN_TYPE_DEFORMITY;
			case PlanTypeEnum.Fracture: return this.langSrv.labels.PLAN_TYPE_FRACTURE;
			default: return planType;
		}
	}

}
