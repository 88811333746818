<div data-testid="SUBSYS2_DASHBOARD_CALENDAR_DAY" class="custom-day p-0" [class.selected]="focused">
	<div class="d-flex justify-content-between">
		<div class="btn-light" [class.focused]="focused" [class.bg-primary]="focused"
			[class.text-muted]="date.month !== currentMonth">
			<strong>{{ date.day }}</strong>
		</div>
		<img data-testid="SUBSYS2_DASHBOARD_CALENDAR_IWRENCH_ICON" class="iwrench-icon" alt="iwrench icon"
			*ngIf="data?.hasIWrenchEvent">
	</div>
	<calendar-events [events]="data?.items"></calendar-events>
</div>