import { Pipe, PipeTransform } from '@angular/core';
import { BoneTypeEnum } from '../../core';


/**
 * Pipe to replace long leg substring in input string
 */
@Pipe({
	name: 'replaceLongLeg'
})
export class ReplaceLongLegPipe implements PipeTransform {

	private readonly _LONG_LEG = "Full Leg";

	constructor() { }

	transform(text: string): string {
		return text?.replace(BoneTypeEnum.LongLeg, this._LONG_LEG);
	}

}
