import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../core';
import { AnatomicalSiteEnum } from '../../models';


/**
 * Pipe to convert Anatomical Site enum to display value.
 */
@Pipe({
	name: 'anatomicalSiteLabel'
})
export class AnatomicalSiteLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(side: AnatomicalSiteEnum): string {
		switch (side) {
			case AnatomicalSiteEnum.LeftLongLeg: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_LONGLEG;
			case AnatomicalSiteEnum.LeftFemur: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_FEMUR;
			case AnatomicalSiteEnum.LeftTibia: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_TIBIA;
			case AnatomicalSiteEnum.LeftAnkle: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_ANKLE;
			case AnatomicalSiteEnum.LeftForefoot: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_FOREFOOT;
			case AnatomicalSiteEnum.LeftHindfoot: return this.langSrv.labels.ANATOMICAL_SITE_LEFT_HINDFOOT;
			case AnatomicalSiteEnum.RightLongLeg: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_LONGLEG;
			case AnatomicalSiteEnum.RightFemur: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_FEMUR;
			case AnatomicalSiteEnum.RightTibia: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_TIBIA;
			case AnatomicalSiteEnum.RightAnkle: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_ANKLE;
			case AnatomicalSiteEnum.RightForefoot: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_FOREFOOT;
			case AnatomicalSiteEnum.RightHindfoot: return this.langSrv.labels.ANATOMICAL_SITE_RIGHT_HINDFOOT;
			default: return side;
		}
	}

}
