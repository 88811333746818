import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ortho-next/auth-core';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { CookieBannerService, UserService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Vertical bar on the left
 */
@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent extends BaseComponent {

	buildVersion: string = environment.buildVer;
	envName: string = environment.name;

	constructor(
		private langSrv: LanguageService,
		private authSrv: AuthService,
		private router: Router,
		private userSrv: UserService,
		private cookieBannerSrv: CookieBannerService
	) {
		super(langSrv);
	}

	/**
	* Navigate to edit profile page
	*/
	editProfile(): void {
		this.router.navigateByUrl('/profile/edit');
	}

	/**
	* Open legal terms page
	*/
	openLegalTerms(): void {
		this.userSrv.getUserCountry().subscribe(code => {
			window.open(`${environment.legalTermsSite}?countryCode=${code}`);
		});
	}

	/**
	* Open about page
	*/
	openAboutPage(): void {
		const lang = this.langSrv.currentLang;
		const aboutSite = environment.aboutSite?.replace('about-en.html', `about-${lang}.html`);
		window.open(aboutSite, '_blank');
	}

	/**
	* Change password
	*/
	changePassword(): void {
		this.authSrv.changePassword();
	}

	/**
	 * Open cookie preferences modal.
	 */
	openCookieModal(): void {
		this.cookieBannerSrv.openPreferencesModal();
	}

	/**
	* Logout
	*/
	logout(): void {
		this.authSrv.singleSignOut();
	}
}
