import { Component, OnDestroy } from '@angular/core';
import { NgbDate, NgbDateNativeAdapter, NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, Observable } from 'rxjs';
import { CalendarDayData } from '../../models';
import { DashboardService } from '../../services';


/**
* Component for calendar in dashboard page.
*/
@Component({
	selector: 'dashboard-calendar',
	templateUrl: './dashboard-calendar.component.html',
	providers: [NgbDateNativeAdapter]
})
export class DashboardCalendarComponent implements OnDestroy {

	constructor(
		private readonly dashbrdSrv: DashboardService,
		private readonly dateAdapter: NgbDateNativeAdapter
	) { }

	/**
	 * Get day template data. 
	 */
	dayTemplateData = (date: NgbDate, current?: { year: number; month: number; }): Observable<CalendarDayData> => {
		return this.dashbrdSrv.strutEvents$.pipe(
			filter(events => !!events?.length),
			map(events => events.filter(ev => date.equals(this.dateAdapter.fromModel(ev.date)))),
			map(events => ({ items: events, hasIWrenchEvent: events.some(ev => ev.hasIWrenchEvent) }))
		);
	};

	/**
	 * Update strut events after calendar month change.
	 */
	changeMonth(ev: NgbDatepickerNavigateEvent): void {
		this.dashbrdSrv.loadEvents(ev.next, !!ev.current);
	}

	/**
	 * Update current selected date.
	 */
	changeDate(ev: NgbDate): void {
		this.dashbrdSrv.updateDate(ev);
	}


	ngOnDestroy(): void {
		this.dashbrdSrv.reset();
	}
}
