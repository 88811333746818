import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';


/**
 * This service handles labels, validation messages and locale.
 */
@Injectable()
export class LanguageService {

	readonly DEFAULT_LANG = 'en';

	private _lang: string = this.DEFAULT_LANG;
	private _localeId: string;
	private _labels: any = {};

	private _isMobileDevice: boolean;

	private _isLabelsLoaded$ = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient) {
		this.checkMobileDevice();
	}

	/**
	 * Get current language.
	 */
	get currentLang(): string {
		return this._lang ?? this.DEFAULT_LANG;
	}

	/**
	* Get labels
	*/
	get labels(): any {
		return this._labels;
	}

	/**
	 * Check if labels are loaded.
	 */
	get isLabelsLoaded(): Observable<boolean> {
		return this._isLabelsLoaded$.asObservable().pipe(first(loaded => !!loaded));
	}

	/**
	* Load labels and validation messages.
	*/
	loadLabels(lang: string = this.DEFAULT_LANG): Observable<any> {
		this._lang = lang ?? this.DEFAULT_LANG;
		return this.http.get(`assets/labels/labels-${this._lang}.json`).pipe(
			tap(labels => {
				this._labels = labels ?? {};
				if (labels) this._isLabelsLoaded$.next(true);
			}),
		);
	}

	/**
	* Set locale and load angular locales file js
	*/
	initLocale(localName: string): Promise<any> {
		console.log('Loading locales: ' + localName);
		const locale = localName?.split('-')[0];
		return import(
			/* webpackInclude: /\.mjs$/ */
			`/node_modules/@angular/common/locales/${locale}.mjs`
		).then(locale => {
			this._localeId = localName;
			registerLocaleData(locale.default);
		}).catch(() => this._localeId = 'en-US');

	}

	/**
	* Get current locale
	*/
	getCurrentLocale(): string {
		return this._localeId;
	}

	/**
	* Set mobile device flag
	*/
	private checkMobileDevice(): void {
		this._isMobileDevice = navigator.userAgent?.includes('Mobile');
	}

	/**
	* Get mobile device flag
	*/
	get isMobileDevice(): boolean {
		return this._isMobileDevice;
	}

}
