/**
* Ring size model
*/
export interface RingSize {
	id: number;
	ringType: RingTypeEnum;
	description: string;
	catalogNumber: string;
	ringTypeDesc?: string;
}

export enum RingTypeEnum {
	FullRing = "FullRing",
	FullRing58Plus38 = "FullRing58Plus38",
	OpenAnteriorRing = "OpenAnteriorRing",
	OpenMedialRing = 'OpenMedialRing',
	OpenPosteriorRing = 'OpenPosteriorRing',
	FootPlate = 'FootPlate',
}