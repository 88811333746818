import { PracticeInfo } from './practice-info';
import { Product } from './product';

/**
* Account information model
*/
export interface UserProfile {
	id: string;
	userName: string;
	firstName: string;
	lastName: string;
	birthDate: Date;
	status: string;
	roles: string[];
	roleInfo: string;
	country: UserProfileCountry;
	products: Product[];
	hospitalOrCompany: string;
	address: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	distributor?: string;
	officePhone: string;
	mobilePhone?: string;
	practice?: PracticeInfo;
	isMigratedTLHEX?: boolean;
	hasToAcceptLegalDocs?: boolean;
	language?: string;
}

export interface UserProfileCountry {
	name: string;
	code: string;
}

export const US_CODE: string = 'US';
export const SURGEON: string = 'Surgeon'; 
