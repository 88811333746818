<form [formGroup]="searchForm">

	<ng-container *ngIf="isCustomerCare">

		<div class="form-group">
			<a data-testid="SUBSYS2_LISTSEARCH_CLEAR_BTN" href="#" onclick="return false;" class="clear-filter"
				(click)="clearFilters()">
				{{ labels.FILTERS_COMPONENT_CLEAR_FILTERS }}
			</a>

			<label data-testid="SUBSYS2_LISTSEARCH_USERNAME_LBL" for="userName">
				{{ labels.FILTERS_COMPONENT_USER_NAME }}
			</label>
			<input data-testid="SUBSYS2_LISTSEARCH_USERNAME_INPUT" type="text" autocomplete="nope" class="form-control"
				id="userName" placeholder="{{ labels.FILTERS_COMPONENT_USER_NAME }}" formControlName="userName"
				[ngClass]="{'is-invalid': searchForm.controls.userName.invalid }" />
			<div class="invalid-feedback" *ngIf="searchForm.controls.userName.invalid">
				{{searchForm.controls.userName["errorMessage"]}}</div>
		</div>

		<div class="form-group">
			<div class="custom-control custom-checkbox custom-control-inline">
				<input data-testid="SUBSYS2_LISTSEARCH_DELETED_INPUT" type="checkbox" class="custom-control-input"
					id="showDeleted" name="showDeleted" formControlName="showDeleted" />
				<label data-testid="SUBSYS2_LISTSEARCH_DELETED_LBL" class="custom-control-label" for="showDeleted">
					{{ labels.FILTERS_COMPONENT_SHOW_DELETED }}
				</label>
			</div>
		</div>

	</ng-container>

	<div class="form-group">
		<ng-container *ngIf="!isCustomerCare">
			<a data-testid="SUBSYS2_LISTSEARCH_CLEAR_BTN" href="#" onclick="return false;" class="clear-filter"
				(click)="clearFilters()">
				{{ labels.FILTERS_COMPONENT_CLEAR_FILTERS }}
			</a>
		</ng-container>

		<label data-testid="SUBSYS2_LISTSEARCH_PATID_LBL" for="patientId">
			{{ labels.FILTERS_COMPONENT_PATIENT_ID }}
		</label>
		<input data-testid="SUBSYS2_LISTSEARCH_PATID_INPUT" type="text" autocomplete="nope" class="form-control"
			id="patientId" placeholder="{{ labels.FILTERS_COMPONENT_PATIENT_ID }}" formControlName="patientId"
			[ngClass]="{'is-invalid': searchForm.controls.patientId.invalid }" />
		<div class="invalid-feedback" *ngIf="searchForm.controls.patientId.invalid">
			{{searchForm.controls.patientId["errorMessage"]}}</div>
	</div>

	<div class="form-group">
		<label data-testid="SUBSYS2_LISTSEARCH_PLANID_LBL" for="planId">
			{{ labels.FILTERS_COMPONENT_PLAN_ID }}
		</label>
		<input data-testid="SUBSYS2_LISTSEARCH_PLANID_INPUT" type="text" autocomplete="nope" class="form-control"
			id="planId" placeholder="{{ labels.FILTERS_COMPONENT_PLAN_ID }}" formControlName="planId"
			[ngClass]="{'is-invalid': searchForm.controls.planId.invalid }" />
		<div class="invalid-feedback" *ngIf="searchForm.controls.planId.invalid">
			{{searchForm.controls.planId["errorMessage"]}}</div>
	</div>

	<div hidden class="form-group" #surgeryDateTarget>
		<label data-testid="SUBSYS2_LISTSEARCH_SURGERYDATE_LBL">{{ labels.FILTERS_COMPONENT_PLAN_SURGERY_DATE }}</label>
		<div class="input-group wrapper-calendar">
			<i data-testid="SUBSYS2_LISTSEARCH_SURGERYDATE_TOGGLE_BTN" class="material-icons input-group-append icon-calendar"
				(click)="surgeryDatePicker.toggle()">today</i>
			<input data-testid="SUBSYS2_LISTSEARCH_SURGERYDATE_LINPUT" class="form-control" type="text"
				placeholder="{{ labels.FILTERS_COMPONENT_PLAN_SURGERY_DATE }}" name="surgeryDateRange"
				[value]="surgeryDateFormat" readonly style="color: #fff">
			<i data-testid="SUBSYS2_LISTSEARCH_SURGERYDATE_CLEAR_BTN"
				class="material-icons input-group-append align-self-center md-18" (click)="surgeryDateClear()">clear</i>
		</div>
	</div>
	<daterangepicker data-testid="SUBSYS2_LISTSEARCH_SURGERYDATE_DATEPICKER" #surgeryDatePicker [range]="surgeryDateRange"
		(rangeChanged)="updateSurgeryDateRange($event)" [positionTarget]="surgeryDateTarget" placement="bottom-left">
	</daterangepicker>

	<div class="form-group" #planDateTarget>
		<label data-testid="SUBSYS2_LISTSEARCH_CREATEDATE_LBL">{{ labels.FILTERS_COMPONENT_PLAN_CREATION_DATE }}</label>
		<div class="input-group wrapper-calendar">
			<i data-testid="SUBSYS2_LISTSEARCH_CREATEDATE_TOGGLE_BTN" class="material-icons input-group-append icon-calendar"
				(click)="planDatePicker.toggle()">today</i>
			<input data-testid="SUBSYS2_LISTSEARCH_CREATEDATE_INPUT" class="form-control" type="text"
				placeholder="{{ labels.FILTERS_COMPONENT_PLAN_CREATION_DATE }}" name="planDateRange" [value]="planDateFormat"
				readonly style="color: #fff">
			<i data-testid="SUBSYS2_LISTSEARCH_CREATEDATE_CLEAR_BTN"
				class="material-icons input-group-append align-self-center md-18" (click)="planDateClear()">clear</i>
		</div>
	</div>
	<daterangepicker data-testid="SUBSYS2_LISTSEARCH_CREATEDATE_DATEPICKER" #planDatePicker [range]="planDateRange"
		(rangeChanged)="updatePlanDateRange($event)" [positionTarget]="planDateTarget" placement="bottom-left">
	</daterangepicker>


	<button data-testid="SUBSYS2_LISTSEARCH_SEARCH_BTN" type="button" class="btn btn-primary"
		[disabled]="searchForm.invalid" (click)="search()">
		{{ labels.FILTERS_COMPONENT_SEARCH }}
	</button>

</form>

<div class="form-group">
	<span data-testid="SUBSYS2_LISTSEARCH_COLLAPSE_BTN" class="d-inline-block all-filters" *ngIf="!isAllFiltersCollapsed"
		(click)="collapseAllFilters()">
		{{ labels.FILTERS_COMPONENT_COLLAPSE_FILTERS }}
	</span>
	<span data-testid="SUBSYS2_LISTSEARCH_SHOW_BTN" class="d-inline-block all-filters" *ngIf="isAllFiltersCollapsed"
		(click)="showAllFilters()">
		{{ labels.FILTERS_COMPONENT_SHOW_FILTERS }}
	</span>
</div>

<form [formGroup]="filtersForm">
	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_GENDER_TOGGLE" class="title-accordion"
			[ngClass]="{'active': isFilterVisible[0] }" (click)="changeFilterVisibility(0)">
			{{ labels.FILTERS_COMPONENT_GENDER }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[0]" formArrayName="gender">
			<div class="custom-control custom-checkbox custom-control-inline"
				*ngFor="let gender of genderGroup.controls; let i = index">
				<input data-testid="SUBSYS2_LISTSEARCH_GENDER_INPUT" type="checkbox" class="custom-control-input"
					id="{{ 'gender_' + genderList[i]  }}" name="gender" [formControlName]="i"
					(change)="submitGender(genderGroup.value)" />
				<label data-testid="SUBSYS2_LISTSEARCH_GENDER_LBL" class="custom-control-label"
					for="{{ 'gender_' + genderList[i]  }}">{{ genderList[i] }}</label>
			</div>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_SIDE_TOGGLE" class="title-accordion" [ngClass]="{'active': isFilterVisible[1] }"
			(click)="changeFilterVisibility(1)">
			{{ labels.FILTERS_COMPONENT_SIDE }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[1]" formArrayName="side">
			<div class="custom-control custom-checkbox custom-control-inline"
				*ngFor="let side of sideGroup.controls; let i = index">
				<input data-testid="SUBSYS2_LISTSEARCH_SIDE_INPUT" type="checkbox" class="custom-control-input"
					id="{{ 'side_' + sideList[i]  }}" name="side" [formControlName]="i" (change)="submitSide(sideGroup.value)" />
				<label data-testid="SUBSYS2_LISTSEARCH_SIDE_LBL" class="custom-control-label"
					for="{{ 'side_' + sideList[i]  }}">{{ sideList[i] | anatomicalSideLabel }}</label>
			</div>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_BONE_TOGGLE" class="title-accordion" [ngClass]="{'active': isFilterVisible[2] }"
			(click)="changeFilterVisibility(2)">
			{{ labels.FILTERS_COMPONENT_BONE_TYPE }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[2]" formArrayName="bone">
			<div class="custom-control custom-checkbox custom-control-inline w-50 mr-0"
				*ngFor="let bone of boneGroup.controls; let i = index">
				<input data-testid="SUBSYS2_LISTSEARCH_BONE_INPUT" type="checkbox" class="custom-control-input"
					id="{{ 'bone_' + boneList[i]  }}" name="bone" [formControlName]="i" (change)="submitBone(boneGroup.value)" />
				<label data-testid="SUBSYS2_LISTSEARCH_BONE_LBL" class="custom-control-label"
					for="{{ 'bone_' + boneList[i]  }}">{{ boneList[i] | boneTypeLabel }}</label>
			</div>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_TYPE_TOGGLE" class="title-accordion" [ngClass]="{'active': isFilterVisible[3] }"
			(click)="changeFilterVisibility(3)">
			{{ labels.FILTERS_COMPONENT_PLAN_TYPE }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[3]" formArrayName="planType">
			<div class="custom-control custom-checkbox custom-control-inline w-50 mr-0"
				*ngFor="let planType of planTypeGroup.controls; let i = index">
				<input data-testid="SUBSYS2_LISTSEARCH_TYPE_INPUT" type="checkbox" class="custom-control-input"
					id="{{ 'planType_' + planType[i]  }}" name="planType" [formControlName]="i"
					(change)="submitPlanType(planTypeGroup.value)" />
				<label data-testid="SUBSYS2_LISTSEARCH_TYPE_LBL" class="custom-control-label"
					for="{{ 'planType_' + planTypeList[i]  }}">{{ planTypeList[i] | planTypeLabel }}</label>
			</div>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_STEP_TOGGLE" class="title-accordion" [ngClass]="{'active': isFilterVisible[4] }"
			(click)="changeFilterVisibility(4)">
			{{ labels.FILTERS_COMPONENT_PLAN_STEP }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[4]">
			<div class="custom-control custom-checkbox custom-control-inline w-50 mr-0">
				<input data-testid="SUBSYS2_LISTSEARCH_PREOP_INPUT" type="checkbox" class="custom-control-input"
					id="preOpFilter" name="planStep" (change)="changePreOpFilter()" />
				<label data-testid="SUBSYS2_LISTSEARCH_PREOP_LBL" class="custom-control-label" for="preOpFilter">
					{{ labels.FILTERS_COMPONENT_PRE_OP }}
				</label>
			</div>
			<div class="custom-control custom-checkbox custom-control-inline w-50 mr-0">
				<input data-testid="SUBSYS2_LISTSEARCH_POSTOP_INPUT" type="checkbox" class="custom-control-input"
					id="postOpFilter" name="planStep" (change)="changePostOpFilter()" />
				<label data-testid="SUBSYS2_LISTSEARCH_POSTOP_LBL" class="custom-control-label" for="postOpFilter">
					{{ labels.FILTERS_COMPONENT_POST_OP }}
				</label>
			</div>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_AGE_TOGGLE" class="title-accordion" [ngClass]="{'active': isFilterVisible[5] }"
			(click)="changeFilterVisibility(5)">
			{{ labels.FILTERS_COMPONENT_AGE }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[5]">
			<input data-testid="SUBSYS2_LISTSEARCH_AGE_INPUT" id="ageSlider" type="text" />
			<span data-testid="SUBSYS2_LISTSEARCH_AGE_TXT" class="help-text">{{ rangeAge }}</span>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_WEIGHT_TOGGLE" class="title-accordion"
			[ngClass]="{'active': isFilterVisible[6] }" (click)="changeFilterVisibility(6)">
			{{ labels.FILTERS_COMPONENT_WEIGHT }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[6]">
			<input data-testid="SUBSYS2_LISTSEARCH_WEIGHT_INPUT" id="weightSlider" type="text" />
			<span data-testid="SUBSYS2_LISTSEARCH_WEIGHT_KG_TXT" class="help-text">{{ rangeWeightKg }}</span><br />
			<span data-testid="SUBSYS2_LISTSEARCH_WEIGHT_LB_TXT" class="help-text">{{ rangeWeightLb }}</span>
		</div>
	</div>

	<div class="wrapper-accordion-form">
		<h5 data-testid="SUBSYS2_LISTSEARCH_HEIGHT_TOGGLE" class="title-accordion"
			[ngClass]="{'active': isFilterVisible[7] }" (click)="changeFilterVisibility(7)">
			{{ labels.FILTERS_COMPONENT_HEIGHT }}
			<span class="material-icons icon-expand">expand_more</span>
		</h5>
		<div class="form-group" [hidden]="!isFilterVisible[7]">
			<input data-testid="SUBSYS2_LISTSEARCH_HEIGHT_INPUT" id="heightSlider" type="text" />
			<span data-testid="SUBSYS2_LISTSEARCH_HEIGHT_CM_TXT" class="help-text">{{ rangeHeightCm }}</span><br />
			<span data-testid="SUBSYS2_LISTSEARCH_HEIGHT_FT_TXT" class="help-text">{{ rangeHeightFt }}</span>
		</div>
	</div>

</form>