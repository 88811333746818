import { Injectable } from '@angular/core';
import { BridgeBase, BridgeResult, CaseModel, InitialFlipType } from '@ortho-next/nextray-core';
import { Event } from '@ortho-next/three-base/three.js/build/three.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppModel } from '../../nextray/Core/AppModel';
import { BridgeActions } from '../../nextray/Core/Bridge';
import { Main } from '../../nextray/Core/Main';
import { ImageOrientationEnum } from '../core';
import { ImageItem } from '../models';



/**
 * This service handles three.js canvas data.
 */
@Injectable()
export class CanvasService {
	private _model: AppModel;
	private _bridge: BridgeBase;
	private _main: Main;

	private _initialized: boolean;
	private _categorizationCanvas: HTMLDivElement;

	isCalibrationVisible: boolean;
	leftLabel: string;
	rightLabel: string;
	topLabel: string
	bottomLabel: string;

	/**
	 * Initialize main canvas container.
	 */
	init(): void {
		if (!this._initialized) {
			this._categorizationCanvas = document.getElementById('categorization-canvas') as HTMLDivElement;
			this._main = new Main(this._categorizationCanvas);
			this._model = this._main?.model;
			this._bridge = this._main?.bridge;
			this._initialized = true;
		} else {
			this.restoreCategorizationCanvas();
		}
	}

	private restoreCategorizationCanvas(): void {
		document.getElementById('categorization-canvas').remove();
		document.getElementById('categorization-canvas-container').appendChild(this._categorizationCanvas);
		this._main.updateCanvasMeasures();
	}

	/**
	 * Set canvas image in base 64 format.
	 */
	setImage(file: File): void {
		this.getBase64Image(file).pipe(
			map(img => { return { imgAP: img } as CaseModel })
		).subscribe(caseModel => this.dispatch('setModel', caseModel));
	}


	/**
	 * Set canvas model to edit.
	 */
	setModel(imageData: ImageItem): void {
		const caseModel = this.caseModelMapper(imageData);
		this.dispatch('setModel', caseModel);
	}

	/**
	 * Get scale factor.
	 */
	get scaleFactor(): number {
		return this._model?.scaleFactorAP;
	}

	/**
	 * Check if registering mode is active.
	 */
	get isPointsRegistering(): boolean {
		return this._model?.stateDescription?.isPointsPositioningRunning;
	}

	/**
	 * Get scale factor.
	 */
	get calibrationEllipsePoints(): { x: number; y: number }[] {
		return this._model?.calibrationEllipsePoints?.map(v => ({ x: v.x, y: v.y }));
	}

	/**
	 * Get calibration ring matrix.
	 */
	get ringMatrix(): number[] {
		return this._model?.ringMatrix?.toArray();
	}

	/**
	 * Get calibration ring angle.
	 */
	get angle(): number {
		return this._model?.angle;
	}

	/**
	 * Get calibration ring frontal rotation.
	 */
	get frontal(): number {
		return this._model?.frontal;
	}

	/**
	* Get calibration ring axial rotation.
	*/
	get axial(): number {
		return this._model?.axial;
	}

	/**
	 * Dispatch an event or action.
	 */
	dispatch<K extends keyof BridgeActions>(type: K, args?: BridgeActions[K]): void {
		this._bridge.mapEvent(type, args);
	}

	/**
	* Add event listener on bridge.
	*/
	addEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
		this._bridge.addEventListener(type, listener);
	}

	/**
	* Remove an event listener on bridge.
	*/
	removeEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
		this._bridge.removeEventListener(type, listener);
	}

	private getBase64Image(file: File): Observable<string> {
		return new Observable<string>(obs => {
			const reader = new FileReader();
			reader.onerror = err => obs.error(err);
			reader.onabort = err => obs.error(err);
			reader.onload = () => obs.next(reader.result as string);
			reader.onloadend = () => obs.complete();
			reader.readAsDataURL(file);
			return () => reader.abort();
		});
	}

	private caseModelMapper(imageData: ImageItem): CaseModel {
		const model = {} as CaseModel;
		model.imgAP = imageData.url;
		model.scaleFactorAP = imageData.scaleFactor;

		switch (imageData.orientation) {
			case ImageOrientationEnum.Horizontal: model.flipTypeAP = InitialFlipType.horizontal; break;
			case ImageOrientationEnum.Vertical: model.flipTypeAP = InitialFlipType.vertical; break;
			case ImageOrientationEnum.Rotate180: model.flipTypeAP = InitialFlipType.rotate180; break;
			default: model.flipTypeAP = undefined;
		}

		return model;
	}

}

export enum BoneImageEnum {
	Left_Leg_AP = 'assets/images/bones/bones_left_orientation_AP.png',
	Left_Leg_LT = 'assets/images/bones/bones_left_orientation_LT.png',
	Right_Leg_AP = 'assets/images/bones/bones_right_orientation_AP.png',
	Right_Leg_LT = 'assets/images/bones/bones_right_orientation_LT.png',

	Left_Foot_AP = 'assets/images/bones/feet_left_orientation_AP.png',
	Left_Foot_LT = 'assets/images/bones/feet_left_orientation_LT.png',
	Right_Foot_AP = 'assets/images/bones/feet_right_orientation_AP.png',
	Right_Foot_LT = 'assets/images/bones/feet_right_orientation_LT.png',
}