import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTooltip, NgbTooltipConfig, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Observable, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { LanguageService, SearchItems, ToastService } from '../../core';
import { ReceivedCloneForm } from '../../models';
import { ReceivedService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Modal component to clone received plan
 */
@Component({
	selector: 'received-clone-modal',
	templateUrl: './received-clone-modal.component.html',
	providers: [NgbTooltipConfig],
	styles: []
})
export class ReceivedCloneModalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	planGuid: string;
	planNotes: string;

	cloneForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(ReceivedCloneForm);

	@ViewChild('tooltipSelect', { static: false }) public tooltipSelect: NgbTooltip;
	@ViewChild('tooltipCreate', { static: false }) public tooltipCreate: NgbTooltip;
	private _tooltipSelectSub: Subscription;
	private _tooltipCreateSub: Subscription;
	private readonly CLOSE_DELAY = 5000;

	isSaving: boolean;

	constructor(
		private langSrv: LanguageService,
		private receivedSrv: ReceivedService,
		public activeModal: NgbActiveModal,
		private formBuilder: RxFormBuilder,
		private toastSrv: ToastService,
		private tooltipConfig: NgbTooltipConfig
	) {
		super(langSrv);
		this.setTooltipConfig();
	}

	ngOnInit() {
		this.initForm();
		this.initCloneForm();
		this.receivedSrv.loadPatientList();
	}

	ngAfterViewInit() {
		if (this.tooltipSelect) {
			this._tooltipSelectSub = this.tooltipSelect.shown.pipe(delay(this.CLOSE_DELAY)).subscribe(() => this.tooltipSelect.close());
			this.tooltipSelect.open();
		}
		if (this.tooltipCreate) {
			this._tooltipCreateSub = this.tooltipCreate.shown.pipe(delay(this.CLOSE_DELAY)).subscribe(() => this.tooltipCreate.close());
			this.tooltipCreate.open();
		}
	}

	/**
	* Get patient list
	*/
	get patientList(): Observable<SearchItems[]> {
		return this.receivedSrv.patientList;
	}

	/**
	* Display name of selected patient 
	*/
	patientFormatter = (result: SearchItems) => result.patientNumber;

	/**
	* Function that filter the patients in autocomplete form
	*/
	filterPatients = this.receivedSrv.filterPatients;

	/**
	* Show options of autocomplete form on focus
	*/
	showPatientList(element: any): void {
		element._elementRef.nativeElement.value = '';
		element._elementRef.nativeElement.dispatchEvent(new Event('input'));
		element._elementRef.nativeElement.focus();
	}

	private setTooltipConfig(): void {
		this.tooltipConfig.placement = 'left';
		this.tooltipConfig.tooltipClass = 'custom-tooltip';
		this.tooltipConfig.triggers = 'manual';
		this.tooltipConfig.autoClose = false;
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.labels,
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	private initCloneForm(): void {
		this.cloneForm = <RxFormGroup>this.formBuilder.formGroup(ReceivedCloneForm,
			{ planGuid: this.planGuid, planNotes: this.planNotes }
		);
	}

	/**
	 * Update existing patient.
	 */
	updatePatient(event: NgbTypeaheadSelectItemEvent<SearchItems>): void {
		this.cloneForm.controls.existingPatient.setValue(event.item);
		this.cloneForm.controls.existingPatient.markAsTouched();
		this.resetNewPatient();
	}
	
	/**
	 * Reset new patient data
	*/
	private resetNewPatient(): void {
		this.cloneForm.controls.patientId.setValue(null);
		this.cloneForm.controls.patientId.markAsUntouched();
		this.cloneForm.controls.patientInitials.setValue(null);
		this.cloneForm.controls.patientInitials.markAsUntouched();
		this.cloneForm.controls.existingPatient.updateValueAndValidity();
	}

	/**
	* Reset selected patient
	*/
	resetSelectedPatient(): void {
		this.cloneForm.controls.existingPatient.setValue(null);
		this.cloneForm.controls.existingPatient.markAsUntouched();
		this.resetPatientIdNotUnique();
		this.cloneForm.controls.patientId.updateValueAndValidity();
		this.cloneForm.controls.patientInitials.updateValueAndValidity();
	}

	/**
	* Reset PatientIdNotUnique flag after validation
	*/
	resetPatientIdNotUnique() {
		if (this.cloneForm.value.patientIdNotUnique) {
			this.cloneForm.controls.patientIdNotUnique.setValue(false);
		}
	}

	/**
	* Trim patient id input field
	*/
	trimPatientId(): void {
		if (this.cloneForm.value.patientId) {
			this.cloneForm.controls.patientId.setValue(this.cloneForm.value.patientId.trim());
		}
	}

	/**
	* Trim patient intitials input field
	*/
	trimPatientInitials(): void {
		if (this.cloneForm.value.patientInitials) {
			this.cloneForm.controls.patientInitials.setValue(this.cloneForm.value.patientInitials.trim());
		}
	}

	/**
	* Trim plan id input field
	*/
	trimPlanId(): void {
		if (this.cloneForm.value.planId) {
			this.cloneForm.controls.planId.setValue(this.cloneForm.value.planId.trim());
		}
	}

	/**
	* Reset PlanIdNotUnique flag after validation
	*/
	resetPlanIdNotUnique() {
		if (this.cloneForm.value.planIdNotUnique) {
			this.cloneForm.controls.planIdNotUnique.setValue(false);
			this.cloneForm.controls.planId.setValue(this.cloneForm.value.planId);
		}
	}

	/**
	* Submit form and handle response messages
	*/
	confirm(): void {
		if (this.cloneForm.valid && !this.isSaving) {
			this.isSaving = true;
			this.receivedSrv.sendCloneForm(this.cloneForm.value).pipe(
				finalize(() => this.isSaving = false)
			).subscribe({
				next: res => {
					this.toastSrv.showSuccess(this.labels['RECEIVED_CLONE_MODAL_COMPONENT_SUCCESS_MESSAGE']);
					this.activeModal.close();
				},
				error: err => {
					this.toastSrv.showError(this.labels['RECEIVED_CLONE_MODAL_COMPONENT_ERROR_MESSAGE']);
					this.handleCloneError(err);
				}
			});
		}
	}

	private handleCloneError(err: Error): void {
		switch (err.message) {
			case 'PatientIdNotUnique': {
				this.cloneForm.controls.patientIdNotUnique.setValue(true);
				this.cloneForm.controls.patientId.setValue(this.cloneForm.controls.patientId.value);
				break;
			}
			case 'PlanIdNotUnique': {
				this.cloneForm.controls.planIdNotUnique.setValue(true);
				this.cloneForm.controls.planId.setValue(this.cloneForm.controls.planId.value);
				break;
			}
		}
	}


	ngOnDestroy() {
		this._tooltipSelectSub?.unsubscribe();
		this._tooltipCreateSub?.unsubscribe();
	}

}
