import { Component, OnInit } from '@angular/core';
import { finalize, forkJoin } from 'rxjs';
import { LanguageService, LoaderService } from '../../core';
import { UserService } from '../../services';
import { BaseComponent } from '../base-component';


/**
 * Component for language selection.
 */
@Component({
	selector: 'language-selection',
	templateUrl: './language-selection.component.html'
})
export class LanguageSelectionComponent extends BaseComponent implements OnInit {

	lang: string = null;
	langList: string[] = [];

	private _prevLang: string = null;

	constructor(
		private langSrv: LanguageService,
		private userSrv: UserService,
		private loaderSrv: LoaderService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		forkJoin([this.userSrv.getLanguages(), this.userSrv.getUserLanguage()]).subscribe(([langs, lang]) => {
			this.langList = [...langs];
			this._prevLang = this.lang = lang ?? this.langSrv.DEFAULT_LANG;
		});
	}

	/**
 * Change user language.
 */
	changeLanguage(): void {
		this.loaderSrv.show();
		this.userSrv.changeLanguage(this.lang).pipe(
			finalize(() => this.loaderSrv.hide())
		).subscribe({
			next: () => {
				this._prevLang = this.lang;
				window.location.reload();
			},
			error: () => this.lang = this._prevLang
		});
	}

}
