import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule, AuthService } from '@ortho-next/auth-core';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, map, of, switchMap } from 'rxjs';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { ErrorsComponent, FallbackComponent, HttpErrorInterceptor, LanguageService, MonitoringErrorHandler, MonitoringService } from './core';
import { DashboardModule } from './dashboard';
import { ListModule } from './list';
import { PatientModule } from './patient';
import { PlanModule } from './plan';
import { ProfileModule } from './profile';
import { CookieBannerService, PatientService, TitleService, UserService } from './services';
import { TemplateModule } from './template';


export function initLocale(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.initLocale(navigator.language);
	};
}
export function initAuth(authSrv: AuthService) {
	return (): Promise<any> => {
		return authSrv.runInitialLoginSequence();
	};
}
export function initUser(authSrv: AuthService, usrSrv: UserService, langSrv: LanguageService) {
	return (): Promise<any> => {
		return firstValueFrom(authSrv.isDoneLoading$.pipe(
			map(() => !!authSrv.accessToken),
			switchMap(isAuth => isAuth ? usrSrv.loadUser() : of(null)),
			switchMap(usr => usr ? langSrv.loadLabels(usr.language) : of(null))
		));
	};
}

@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		BrowserModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		TemplateModule,
		PatientModule,
		PlanModule,
		ListModule,
		ProfileModule,
		DashboardModule,
		HttpClientModule
	],
	providers: [
		CookieService,
		MonitoringService,
		{ provide: ErrorHandler, useClass: MonitoringErrorHandler },
		LanguageService,
		PatientService,
		TitleService,
		CookieBannerService,
		{ provide: APP_INITIALIZER, useFactory: initLocale, deps: [LanguageService], multi: true },
		{ provide: APP_INITIALIZER, useFactory: initAuth, deps: [AuthService], multi: true },
		{ provide: APP_INITIALIZER, useFactory: initUser, deps: [AuthService, UserService, LanguageService], multi: true },
		{ provide: LOCALE_ID, useFactory: (langService: LanguageService) => langService.getCurrentLocale(), deps: [LanguageService] },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
