import { Component, Input } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDayData } from '../../models';


/**
 * Component for custom day in dashboard calendar.
 */
@Component({
	selector: 'app-calendar-day',
	templateUrl: './calendar-day.component.html'
})
export class CalendarDayComponent {

	@Input() focused: boolean;
	@Input() date: NgbDate;
	@Input() currentMonth: number;
	@Input() data: CalendarDayData | undefined;

}
