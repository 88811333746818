import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, ExpressionOperator, LoaderService, PatientRepository, SearchFields, SearchItems, StrutEvent, TlhexRepository } from '../core';
import { DailyEventItem, EventItem, StrutEventExtended, strutEventType } from '../models';



/**
 * This Service handles attachment data.
 */
@Injectable()
export class DashboardService {

	private readonly _strutEvents: BehaviorSubject<EventItem[]> = new BehaviorSubject<EventItem[]>(null);
	private readonly _dailyEvents: BehaviorSubject<DailyEventItem[]> = new BehaviorSubject<DailyEventItem[]>(null);
	private readonly _selectedDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

	constructor(
		private readonly tlhexRepo: TlhexRepository,
		private readonly patRepo: PatientRepository,
		private readonly loaderSrv: LoaderService
	) { }

	/**
	 * Get current strut events list.
	 */
	get strutEvents$(): Observable<EventItem[]> {
		return this._strutEvents.asObservable();
	}

	/**
	 * Get current daily events map.
	 */
	get dailyEvents$(): Observable<any[]> {
		return this._dailyEvents.asObservable();
	}

	/**
	 * Get current selected date.
	 */
	get selectedDate$(): Observable<Date> {
		return this._selectedDate.asObservable();
	}

	/**
	 * Update current selected date. 
	 */
	updateDate(nextDate?: NgbDate): void {
		const prevDate = fromDate(this._selectedDate.value);
		if (!!nextDate && !nextDate.equals(prevDate)) {
			const date = toDate(nextDate);
			this._selectedDate.next(date);
			this.updateDailyEvents();
		}
	}

	private updateDailyEvents(): void {
		if (this._strutEvents.value?.length && this._selectedDate.value) {
			const dailyEvents = this.dailyEventsMapper(this._strutEvents.value, this._selectedDate.value);
			this._dailyEvents.next(dailyEvents);
		} else {
			this._dailyEvents.next(null);
		}
	}

	private clearEventList(): void {
		this._selectedDate.next(null);
		this._dailyEvents.next(null);
	}

	/**
	 * Get plan/patient details of input events.
	 * @param events 
	 * @returns Map of plan guid -> plan detail
	 */
	private getPlanDetails(events: StrutEvent[]): Observable<Map<string, SearchItems[]>> {
		const planGuidList = _.uniq(events.map(ev => ev.caseId));
		return this.patRepo.search({
			page: 0,
			top: 0,
			filters: [{ name: SearchFields.planGuid, operator: ExpressionOperator.In, value: planGuidList }],
			patientOnly: false
		}).pipe(
			map(this.handleApiResponse),
			map(res => res.items),
			map(list => _.groupBy(list, 'planId')),
		);
	}

	/**
	 * Get struts events between input dates.
	 */
	private getStrutsEvents(from: Date, to: Date): Observable<StrutEvent[]> {
		return this.tlhexRepo.getStrutsEvents(from, to).pipe(
			map(this.handleApiResponse)
		)
	}

	/**
	 * Update events list according input month.
	 */
	loadEvents(nextMonth: { year: number; month: number }, reset: boolean): void {
		if (reset) this.clearEventList();
		const from = new Date(nextMonth.year, nextMonth.month - 1);
		const to = new Date(nextMonth.year, nextMonth.month);
		this.loaderSrv.show();
		this.getStrutsEvents(from, to).pipe(
			concatMap(events => this.getPlanDetails(events).pipe(map(details => ({ events, details })))),
			map(({ events, details }) => this.eventListMapper(events, details)),
			tap(list => this._strutEvents.next(list)),
			finalize(() => this.loaderSrv.hide())
		).subscribe(() => this.updateDailyEvents());
	}

	/**
	 * Map strut event to calendar event item. 
	 */
	private eventListMapper(events: StrutEvent[], details: Map<string, SearchItems[]>): EventItem[] {
		const ordered = _.orderBy(events, ['dateOfAdjustment']);
		ordered.forEach((ev: StrutEventExtended) => {
			ev.date = new Date(new Date(ev.dateOfAdjustment).setHours(0, 0, 0, 0));
			ev.type = strutEventType(ev);
			ev.header = `${ev.date}-${ev.type}`;
			const plan = details[ev.caseId]?.[0] as SearchItems;
			ev.planName = plan?.planNumber;
			ev.patientName = plan?.patientNumber;
		});
		const grouped = _.groupBy(ordered, 'header');
		let result: EventItem[] = [];
		for (const key in grouped) {
			let items = grouped[key] as StrutEventExtended[];
			if (items.length > 1) {
				items = _.uniqWith(items, function (item1: StrutEventExtended, item2: StrutEventExtended) {
					return item1.caseId === item2.caseId && item1.header === item2.header;
				});
			}
			result.push({
				date: grouped[key][0].date,
				type: grouped[key][0].type,
				items: items,
				hasIWrenchEvent: items.some(i => i.hasIWrench)
			});
		}
		return result;
	}

	/**
	 * Map calendar event items to dashboard event items.
	 */
	dailyEventsMapper(list: EventItem[], day?: Date): DailyEventItem[] {
		let result = [];
		if (list?.length && day) {
			const filteredItems = list?.filter(i => i.date.getDate() === day.getDate() && i.date.getMonth() === day.getMonth() && i.date.getFullYear() === day.getFullYear());
			const items = filteredItems.flatMap(i => i.items);
			const groupedData = _.groupBy(items, 'patientName');
			for (let key in groupedData) {
				const items = groupedData[key] as StrutEventExtended[];
				result.push({ patient: key, items: items, hasIWrenchEvent: items.some(i => i.hasIWrench) } as DailyEventItem);
			}
		}
		return result;
	}

	private handleApiResponse<T>(response: ApiResponse<T>) {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error("Generic error");
		}
	}

	reset(): void {
		this._strutEvents.next(null);
		this._dailyEvents.next(null);
		this._selectedDate.next(new Date());
	}

}

export function fromDate(date: Date): NgbDate {
	return date ? new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()) : null;
}

export function toDate(date: NgbDateStruct): Date {
	return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
}