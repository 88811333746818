<div class="">

	<div class="row">

		<div class="col-lg-6 d-flex">
			<span data-testid="SUBSYS2_PLANLIST_RESULTS_TXT" class="results">
				{{collectionSize}} {{labels.PLAN_TABLE_COMPONENT_RESULTS}}
			</span>
		</div>

	</div>

</div>

<div class="panel panel-thead">

	<div class="row no-gutters">

		<div data-testid="SUBSYS2_PLANLIST_HEADER_PATID" class="col"
			[ngClass]="{'active': activeColumn === fields.patNumber }">
			<sortable-header [name]="fields.patNumber" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PATIENT_ID}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_PLANID" class="col"
			[ngClass]="{'active': activeColumn === fields.planNumber }">
			<sortable-header [name]="fields.planNumber" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_ID}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_YPE" class="col"
			[ngClass]="{'active': activeColumn === fields.planType }">
			<sortable-header [name]="fields.planType" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_TYPE}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_STEP" class="col"
			[ngClass]="{'active': activeColumn === fields.isPostOperative }">
			<sortable-header [name]="fields.isPostOperative" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_STEP}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_SIDE" class="col" [ngClass]="{'active': activeColumn === fields.side }">
			<sortable-header [name]="fields.side" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_SIDE}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_BONE" class="col"
			[ngClass]="{'active': activeColumn === fields.boneType }">
			<sortable-header [name]="fields.boneType" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_BONE_TYPE}}
			</sortable-header>
		</div>
		<ng-container *ngIf="isTlhexUser">
			<div data-testid="SUBSYS2_PLANLIST_HEADER_FRAMEID" class="col"
				[ngClass]="{'active': activeColumn === fields.planFrameId }">
				<sortable-header [name]="fields.planFrameId" (sort)="onSort($event)">
					{{labels.PLAN_TABLE_COMPONENT_PLAN_FRAME_ID}}
				</sortable-header>
			</div>
			<div data-testid="SUBSYS2_PLANLIST_HEADER_STATUS" class="col no-link">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_STATUS}}
			</div>
			<div data-testid="SUBSYS2_PLANLIST_HEADER_DAYSLEFT" class="col"
				[ngClass]="{'active': activeColumn === fields.planDaysLeft }">
				<sortable-header [name]="fields.planDaysLeft" (sort)="onSort($event)">
					{{labels.PLAN_TABLE_COMPONENT_PLAN_DAYS_LEFT}}
				</sortable-header>
			</div>
		</ng-container>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_CREATEDATE" class="col"
			[ngClass]="{'active': activeColumn === fields.planCreatedDate }">
			<sortable-header [name]="fields.planCreatedDate" (sort)="onSort($event)">
				{{labels.PLAN_TABLE_COMPONENT_PLAN_CREATION_DATE}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PLANLIST_HEADER_ACTIONS" class="col no-link stretch-col"
			[class.shrinked]="!isFiltersCollapsed">
			{{labels.PLAN_TABLE_COMPONENT_ACTIONS}}
		</div>

	</div>

</div>

<!-- Single line -->
<div class="panel panel-tr" *ngFor="let plan of planList; let i = index">

	<div class="row no-gutters">

		<div class="col">
			{{plan.patientNumber}}
		</div>
		<div class="col">
			<a href="" onclick="return false;" (click)="editPlan(plan.planId)"
				*ngIf="!plan.caseIsPublished && !plan.caseIsRevoked; else noEditLink">
				{{plan.planNumber}}
			</a>
			<ng-template #noEditLink>{{plan.planNumber}}</ng-template>
		</div>
		<div class="col">
			{{plan.caseType | planTypeLabel }}
		</div>
		<div class="col">
			{{plan.isPostOperative ? 'PostOp' : 'PreOp'}}
		</div>
		<div class="col">
			{{plan.caseSide | anatomicalSideLabel }}
		</div>
		<div class="col">
			{{plan.boneType | boneTypeLabel}}
		</div>
		<ng-container *ngIf="isTlhexUser">
			<div class="col">
				{{plan.frameID ?? '--' }}
			</div>
			<div class="col">
				<div *ngIf="plan.caseStatus; else statusNull"
					class="d-flex justify-content-between align-items-center flex-wrap flex-column">
					<button type="button" class="btn colored-chip"
						[ngClass]="statusClassEnum[plan.caseStatus]">{{plan.caseStatus}}</button>
					<img class="mt-3 published-icon" alt="published icon" *ngIf="plan.caseIsPublished" />
					<img class="mt-3 revoked-icon" alt="revoked icon" *ngIf="plan.caseIsRevoked" />
				</div>
				<ng-template #statusNull>--</ng-template>
			</div>
			<div class="col">
				{{plan.caseDaysLeft !== null ? plan.caseDaysLeft : '--' }}
			</div>
		</ng-container>
		<div class="col">
			{{plan.caseCreationDate | convertDate }}
		</div>
		<div class="col stretch-col" [class.shrinked]="!isFiltersCollapsed">
			<plan-action class="text-left" [item]="plan" [isTlhexUser]="isTlhexUser"></plan-action>
		</div>

	</div>

</div>


<!-- Pagination -->
<pagination></pagination>