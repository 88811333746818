<div class="panel panel-plan">

	<div data-testid="SUBSYS2_PLANFORM_LIBRARY_DISABLED_BOX" class="panel-plan-disabled" *ngIf="isBoxDisabled"></div>

	<div class="row">

		<div class="col-6">
			<div *ngIf="form" class="w-100">
				<label data-testid="SUBSYS2_PLANFORM_ANALYSISTYPE_LBL">
					{{labels.PLAN_FORM_COMPONENT_ANALYSIS_TYPE}}
				</label>
				<div class="d-flex justify-content-center" style="margin: 0 6rem;">
					<bone-selection [disabled]="isImageUpload || isCheckUp" [site]="formValue.anatomicalSite"
						(onSiteChange)="updateAnatomicalSite($event)" *ngIf="!isSkeletonLoading; else skeletonImg"></bone-selection>
					<ng-template #skeletonImg>
						<img class="w-100" src="assets/images/bilateralPlan.png" alt="skeleton">
					</ng-template>
				</div>
				<label data-testid="SUBSYS2_PLANFORM_ANALYSISTYPE_TXT" class="label-bone-type"
					*ngIf="formCtrl.anatomicalSite.valid">
					{{ formValue.anatomicalSite | anatomicalSiteLabel | uppercase }}
				</label>
			</div>

		</div>

		<div class="col-6">
			<patient-attachment class="panel-attachment-plan w-100" [dropListConnected]="['apImage', 'lateralImage']"
				[isDisabled]="isAnotherUser"></patient-attachment>
		</div>

	</div>
</div>