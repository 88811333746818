import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StateTypes } from '../../../nextray/Core/Context';
import { BoneTypeEnum, LanguageService, SearchItems, ToastService } from '../../core';
import { PlanSendModalComponent } from '../../plan';
import { ListService, PlanService, TlhexService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Plan actions component on plan table
*/
@Component({
	selector: 'plan-action',
	templateUrl: './plan-action.component.html'
})
export class PlanActionComponent extends BaseComponent implements OnDestroy {

	@Input() set item(val: SearchItems) {
		this.plan = val;
		this._usrSub$ = this.userSrv.isCustomerCareOtherUser(val.userId).subscribe(res => this.isReadonly = res);
	}
	@Input() isTlhexUser: boolean;

	plan: SearchItems;
	isReadonly: boolean;

	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		private planSrv: PlanService,
		private listSrv: ListService,
		private modalSrv: NgbModal,
		private tlhexSrv: TlhexService,
		private toastSrv: ToastService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	/**
	* Check if view action is visible.
	*/
	get isViewVisible(): boolean {
		return this.isTlhexUser && (
			(!this.plan.caseAPImagesGuid && !this.plan.caseLTImagesGuid) ||
			this.plan.caseModelStatus === StateTypes.tlhex ||
			this.plan.caseModelStatus === StateTypes.tlhexSchedule
		);
	}

	/**
	* Check if myHexPlan case settings action is visible.
	*/
	get isMyHexPlanSettingsVisible(): boolean {
		return this.isTlhexUser && (this.plan.caseIsPublished || this.plan.caseIsRevoked);
	}

	/**
	* Navigate to tlhex view case page.
	*/
	view(): void {
		window.location.href = environment.tlhexViewSite + '?caseGuid=' + this.plan?.planId;
	}

	/**
	* Navigate to myHexPlan case settings.
	*/
	viewPlanSettings(): void {
		if (!this.isReadonly) {
			window.location.href = `${environment.tspPlanSite}${this.plan.planId}`;
		}
	}

	/**
	* Navigate to plan edit page
	*/
	edit(): void {
		if (!this.isReadonly) {
			this.router.navigate(['/plan/edit'], { queryParams: { planGuid: this.plan?.planId } });
		}
	}

	/**
	* Open confirmation modal before delete plan
	*/
	openDeleteModal(): void {
		if (!this.isReadonly && !this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['PLAN_ACTION_COMPONENT_DELETE_MODAL_TITLE'],
				message: this.labels['PLAN_ACTION_COMPONENT_DELETE_MODAL_MESSAGE'],
				cancelBtn: this.labels['PLAN_ACTION_COMPONENT_DELETE_MODAL_NO'],
				confirmBtn: this.labels['PLAN_ACTION_COMPONENT_DELETE_MODAL_YES']
			};
			modalRef.result.then(() => this.delete()).catch(error => false);
		}
	}

	private delete(): void {
		this.planSrv.deletePlan(this.plan?.planId).subscribe(() => this.listSrv.search());
	}

	/**
	* Open modal to send plan
	*/
	openSendModal(): void {
		const modalRef: NgbModalRef = this.modalSrv.open(PlanSendModalComponent, {
			centered: true, backdrop: 'static', windowClass: 'modal-form', size: 'lg'
		});
		(modalRef.componentInstance as PlanSendModalComponent).planGuid = this.plan?.planId;
		(modalRef.componentInstance as PlanSendModalComponent).userGuid = this.plan?.userId;
	}

	/**
	* Check if edit is visible.
	*/
	get isEditVisible(): boolean {
		return !this.plan.caseIsPublished && !this.plan.caseIsRevoked;
	}

	/**
	* Check if delete is visible.
	*/
	get isDeleteVisible(): boolean {
		return !this.plan.caseIsPublished && !this.plan.caseIsRevoked;
	}

	/**
	 * Check if postop create action is visible.
	 */
	get isPostopCreateVisible(): boolean {
		return !this.plan.isPostOperative && (this.isTlhexMouSaved || this.isHexrayMouSaved);
	}

	private get isTlhexMouSaved(): boolean {
		return !this.plan.caseAPImagesGuid && !this.plan.caseLTImagesGuid && !!this.plan.frameID && !this.plan.caseModelStatus;
	}

	private get isHexrayMouSaved(): boolean {
		return !!this.plan.caseAPImagesGuid && !!this.plan.caseLTImagesGuid && !!this.plan.frameID && this.plan.caseModelStatus === StateTypes.tlhexSchedule;
	}

	/**
	 * Open confirmation modal before postop creation.
	 */
	openPostopCreateModal(): void {
		if (!this.isReadonly && !this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['PLAN_ACTION_COMPONENT_POSTOP_MODAL_TITLE'],
				message: this.labels['PLAN_ACTION_COMPONENT_POSTOP_MODAL_MESSAGE'],
				cancelBtn: this.labels['PLAN_ACTION_COMPONENT_POSTOP_MODAL_NO'],
				confirmBtn: this.labels['PLAN_ACTION_COMPONENT_POSTOP_MODAL_YES']
			};
			modalRef.result.then(() => this.createPostopPlan()).catch(error => false);
		}
	}

	private createPostopPlan(): void {
		this.tlhexSrv.createPostopPlan(this.plan.planId, this.plan.userId).subscribe({
			next: (planId: string) => this.router.navigate(['/plan/edit'], { queryParams: { planGuid: planId } }),
			error: () => this.toastSrv.showError(this.labels['PLAN_ACTION_COMPONENT_CREATE_POSTOP_ERROR'])
		});
	}

	/**
	* Check if analysis is visible.
	*/
	get isAnalysisVisible(): boolean {
		return !this.plan.caseIsRevoked;
	}

	/**
	* Check if analysis is enabled
	*/
	get isAnalysisEnabled(): boolean {
		return !!this.plan?.caseProducts?.length && !this.isCheckUpInvalid;
	}

	private get isCheckUpInvalid(): boolean {
		return this.plan.caseIsCheckUp && this.plan.boneType === BoneTypeEnum.LongLeg && (!this.plan.caseAPImagesGuid || !this.plan.caseLTImagesGuid);
	}

	/**
	* Navigate to ray site
	*/
	navigateToApp(): void {
		if (this.isAnalysisEnabled) {
			let appRedirectUrl;
			if (!this.plan?.caseAPImagesGuid && !this.plan?.caseLTImagesGuid) {
				appRedirectUrl = environment.tlhexSite;
			} else if (this.plan?.caseModelStatus === StateTypes.RPM) {
				appRedirectUrl = environment.fitboneSite;
			} else if (this.plan?.caseModelStatus === StateTypes.tlhex) {
				appRedirectUrl = environment.tlhexRaySite;
			} else if (this.plan?.caseModelStatus === StateTypes.tlhexSchedule) {
				appRedirectUrl = environment.tlhexSite;
			} else {
				appRedirectUrl = environment.nextraySite;
			}

			window.location.href = appRedirectUrl + '?caseGuid=' + this.plan?.planId;
		}
	}


	ngOnDestroy(): void {
		this._usrSub$?.unsubscribe();
	}
}
