<div class="d-flex align-items-center" *ngIf="langList?.length > 1">
	<i class="material-icons mr-2">language</i>
	<form class="nav-form mr-5">
		<div class="form-group pb-0 mb-0">
			<select class="form-control custom-select" [(ngModel)]="lang" [ngModelOptions]="{'standalone': true}"
				(ngModelChange)="changeLanguage()">
				<option *ngFor="let lang of langList" [value]="lang">
					{{ labels["LANGUAGE_" + lang.toUpperCase()] }}
				</option>
			</select>
		</div>
	</form>
</div>