import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserRepository } from './Iuser.repository';
import { ApiResponse, Dictionary, UserLastSignIn, UserProfile } from './models';


@Injectable()
export class UserRepository implements IUserRepository {

	constructor(private httpClient: HttpClient) { }


	getUserProfile(): Observable<ApiResponse<UserProfile>> {
		return this.httpClient.get<ApiResponse<UserProfile>>(environment.domainUserAPI + "/api/users/GetUserProfile");
	}

	getUserGuid(username: string): Observable<ApiResponse<string>> {
		const params = new HttpParams().set('username', username);
		return this.httpClient.get<ApiResponse<string>>(environment.domainUserAPI + "/api/users/GetUserGuid", { params: params });
	}

	editUser(profile: UserProfile): Observable<ApiResponse<any>> {
		return this.httpClient.put<ApiResponse<any>>(environment.domainUserAPI + "/api/users/EditUser", profile);
	}

	getDictionaries(dictionaryList: string): Observable<ApiResponse<Dictionary[]>> {
		const params = new HttpParams().set('nameList', dictionaryList);
		return this.httpClient.get<ApiResponse<Dictionary[]>>(environment.domainUserAPI + "/api/request/getdictionaries", { params: params });
	}

	getLastSignIn(userId: string): Observable<ApiResponse<UserLastSignIn>> {
		const params = new HttpParams().set('userId', userId);
		return this.httpClient.get<ApiResponse<UserLastSignIn>>(environment.domainUserAPI + "/api/users/LastSignIn", { params });
	}

	getLanguages(): Observable<ApiResponse<string[]>> {
		return this.httpClient.get<ApiResponse<string[]>>(environment.domainUserAPI + "/api/users/GetLanguages");
	}

	changeLanguage(languageCode: string): Observable<ApiResponse<void>> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.httpClient.patch<ApiResponse<void>>(environment.domainUserAPI + "/api/users/ChangeUserLanguage", `"${languageCode}"`, { headers: headers });
	}

}
