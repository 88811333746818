<div class="nav-top">
	<div class="nav-left-side">
		<span data-testid="SUBSYS2_NAVBAR_WELCOME_TXT" class="welcome-text">
			{{ labels.NAVBAR_COMPONENT_WELCOME }} {{firstName}} {{lastName}}
		</span>
	</div>
	<div class="nav-right-side position-static" ngbDropdown container="body" placement="bottom-right"
		(openChange)="getGuideList($event)">

		<span data-testid="SUBSYS2_NAVBAR_DEMO_TXT" class="demo-text"
			[ngClass]="{'wrapper-demo-text wrapper-second': isMobile}" *ngIf="isPlatformDemo">
			{{ labels.NAVBAR_COMPONENT_DEMO_MSG}}
		</span>

		<language-selection></language-selection>

		<span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>

		<a data-testid="SUBSYS2_NAVBAR_MYHEXPLAN_BTN" class="logo-text" [href]="myhexplanUrl" target="_blank"
			*ngIf="isMyhexplan">
			{{labels.NAVBAR_COMPONENT_LINK_MYHEXPLAN}}
		</a>

		<a data-testid="SUBSYS2_NAVBAR_EMAIL_BTN" class="nav-top-link" href="mailto:{{customerCareEmail}}"
			[title]="labels.NAVBAR_COMPONENT_CONTACT_US"><i class="material-icons">mail_outline</i>
		</a>

		<a data-testid="SUBSYS2_NAVBAR_GUIDE_BTN" class="nav-top-link" ngbDropdownToggle
			[title]="labels.NAVBAR_COMPONENT_GUIDE">
			<img class="ifu-icon" alt="ifu icon">
		</a>

		<a data-testid="SUBSYS2_NAVBAR_HELP_BTN" class="nav-top-link" href="" onclick="return false"
			(click)="openHelpModal()" [title]="labels.NAVBAR_COMPONENT_HELP">
			<i class="material-icons-outlined">live_help</i>
		</a>

		<div class="dropdown-menu no-line" ngbDropdownMenu style="max-width: 30rem;">
			<a data-testid="SUBSYS2_NAVBAR_IFUSITE_BTN" [href]="eifuSiteUrl" target="_blank" class="dropdown-item">
				{{ labels.NAVBAR_COMPONENT_LINK_EIFU }}
			</a>
			<a data-testid="SUBSYS2_NAVBAR_GUIDEITEM_BTN" href="" onclick="return false;" class="dropdown-item"
				*ngFor="let guide of guideList" (click)="openGuide(guide.path)">
				{{ labels[guide.labelKey] }}
			</a>
		</div>

	</div>
</div>