import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LanguageService } from '../core';


/**
 * This service handles cookie banner.
 */
@Injectable()
export class CookieBannerService {

	constructor(private langSrv: LanguageService) { }

	/**
	 * Open cookie banner.
	 */
	openBanner(): void {
		this.langSrv.isLabelsLoaded.subscribe(() => {
			this.initConfig();
			this.updateLang();
			this.showBanner();
		});
	}

	private initConfig(): void {
		(window as any)._iub = (window as any)._iub || [];
		(window as any)._iub.csConfiguration = DEFAULT_BANNER_CONFIG;

		const script: HTMLScriptElement = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://cs.iubenda.com/autoblocking/${environment.cookieSiteId}.js`;
		document.getElementsByTagName('head')[0].appendChild(script);

		const script1: HTMLScriptElement = document.createElement('script');
		script1.type = 'text/javascript';
		script1.src = 'https://cdn.iubenda.com/cs/gpp/stub.js';
		document.getElementsByTagName('head')[0].appendChild(script1);
	}

	private updateLang(): void {
		(window as any)._iub.csConfiguration.i18n = {
			...(window as any)._iub.csConfiguration.i18n,
			[this.langSrv.currentLang]: {
				"banner": {
					"dynamic": {
						"body": this.langSrv.labels.COOKIE_BANNER_BODY_TEXT?.replace('{{legal-terms-site}}', environment.legalTermsSite)
					},
					"accept_button_caption": this.langSrv.labels.COOKIE_BANNER_ACCEPT_BTN,
					"reject_button_caption": this.langSrv.labels.COOKIE_BANNER_REJECT_BTN,
					"customize_button_caption": this.langSrv.labels.COOKIE_BANNER_CAPTION_BTN
				}
			}
		};
		(window as any)._iub.csConfiguration.lang = this.langSrv.currentLang;
	}

	private showBanner(): void {
		const script: HTMLScriptElement = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js';
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	/**
	 * Open cookie preferences modal.
	 */
	openPreferencesModal(): void {
		(window as any)._iub.cs.api.openPreferences();
	}
}

const DEFAULT_BANNER_CONFIG = {
	"askConsentAtCookiePolicyUpdate": true,
	"cookiePolicyInOtherWindow": true,
	"countryDetection": true,
	"enableFadp": true,
	"enableLgpd": true,
	"enableUspr": true,
	"lang": "en",
	"lgpdAppliesGlobally": false,
	"perPurposeConsent": true,
	"purposes": "1,2",
	"reloadOnConsent": true,
	"siteId": environment.cookieSiteId,
	"cookiePolicyId": environment.cookiePolicyId,
	"cookiePolicyUrl": environment.legalTermsSite,
	"privacyPolicyUrl": environment.legalTermsSite,
	"privacyPolicyNoticeAtCollectionUrl": environment.legalTermsSite,
	"banner": {
		"acceptButtonDisplay": true,
		"closeButtonRejects": true,
		"customizeButtonDisplay": true,
		"explicitWithdrawal": true,
		"listPurposes": true,
		"position": "bottom",
		"rejectButtonDisplay": true,
		"showPurposesToggles": true
	}
};

