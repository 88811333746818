import { contains, maxDate, maxLength, minDate, pattern, prop, required } from '@rxweb/reactive-form-validators';
import { AnatomicalSideEnum, BoneTypeEnum, PlanTypeEnum, ReferenceTypeEnum } from '../core';

const specialChars: RegExp = /^[^<>%#&?,]+$/;
const datePattern: RegExp = /^[1-9]\d{3}-\d\d-\d\d$/;


/**
* Plan create/edit form model
*/
export class PlanForm {

	@required()
	patientGuid: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PLANID" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLANID" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLANID" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_UNIQUE_PLANID", conditionalExpression: (x: PlanForm) => !!x.planIdNotUnique })
	planId: string;

	@prop({ defaultValue: false })
	planIdNotUnique?: boolean;

	@maxDate({ value: new Date(2299, 12, 1), operator: '<', messageKey: "VALIDATION_MESSAGE_INVALID_PLAN_SURGERY_DATE" })
	@minDate({ value: new Date(1899, 12, 1), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_PLAN_SURGERY_DATE" })
	surgeryDate?: Date;

	@prop()
	surgeryDateFormat?: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PLAN_ANATOMICAL_SITE" })
	anatomicalSite: AnatomicalSiteEnum;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PLAN_ANATOMICAL_SITE" })
	side: AnatomicalSideEnum;

	@required()
	boneType: BoneTypeEnum;

	@required()
	referenceType: ReferenceTypeEnum;

	@required()
	postOperative: boolean;

	@required()
	planType: PlanTypeEnum;

	@maxLength({ value: 1000, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLAN_NOTES" })
	//@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLAN_NOTES" })
	notes?: string;

	@prop({ defaultValue: null })
	apImageGuid?: string;

	@prop({ defaultValue: null })
	ltImageGuid?: string;
}

export enum AnatomicalSiteEnum {
	RightLongLeg = "RightLongLeg",
	RightFemur = "RightFemur",
	RightTibia = "RightTibia",
	RightAnkle = "RightAnkle",
	RightForefoot = "RightForefoot",
	RightHindfoot = "RightHindfoot",
	LeftLongLeg = "LeftLongLeg",
	LeftFemur = "LeftFemur",
	LeftTibia = "LeftTibia",
	LeftAnkle = "LeftAnkle",
	LeftForefoot = "LeftForefoot",
	LeftHindfoot = "LeftHindfoot"
}
