import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService, RingTypeEnum } from '../../core';


/**
 * Pipe to convert Ring Type enum to display value.
 */
@Pipe({
	name: 'ringTypeLabel'
})
export class RingTypeLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(ringType: RingTypeEnum, isFoot: boolean): string {
		switch (ringType) {
			case RingTypeEnum.FootPlate: return this.langSrv.labels.RING_TYPE_FULL_PLATE;
			case RingTypeEnum.FullRing: return this.langSrv.labels.RING_TYPE_FULL;
			case RingTypeEnum.FullRing58Plus38: return this.langSrv.labels.RING_TYPE_FULL_58PLUS38;
			case RingTypeEnum.OpenAnteriorRing: return isFoot ? this.langSrv.labels.RING_TYPE_OPEN_DORSAL : this.langSrv.labels.RING_TYPE_OPEN_ANTERIOR;
			case RingTypeEnum.OpenMedialRing: return this.langSrv.labels.RING_TYPE_OPEN_MEDIAL;
			case RingTypeEnum.OpenPosteriorRing: return isFoot ? this.langSrv.labels.RING_TYPE_OPEN_PLANTAR : this.langSrv.labels.RING_TYPE_OPEN_POSTERIOR;
			default: return ringType;
		}
	}

}
