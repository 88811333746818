<div class="">

	<div class="row">

		<div class="col-lg-6 d-flex">
			<span data-testid="SUBSYS2_PATLIST_RESULTS_TXT" class="results">
				{{collectionSize}} {{labels.PATIENT_TABLE_COMPONENT_RESULTS}}
			</span>
		</div>

	</div>

</div>

<div class="panel panel-thead">

	<div class="row no-gutters">

		<div class="col short-col" *ngIf="isTlhexUser"></div>
		<div data-testid="SUBSYS2_PATLIST_HEADER_PATID" class="col"
			[ngClass]="{'active': activeColumn === fields.patNumber }">
			<sortable-header [name]="fields.patNumber" (sort)="onSort($event)">
				{{labels.PATIENT_TABLE_COMPONENT_PATIENT_ID}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PATLIST_HEADER_INITIALS" class="col"
			[ngClass]="{'active': activeColumn === fields.patInitials }">
			<sortable-header [name]="fields.patInitials" (sort)="onSort($event)">
				{{labels.PATIENT_TABLE_COMPONENT_PATIENT_INITIALS}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PATLIST_HEADER_GENDER" class="col"
			[ngClass]="{'active': activeColumn === fields.patGender }">
			<sortable-header [name]="fields.patGender" (sort)="onSort($event)">
				{{labels.PATIENT_TABLE_COMPONENT_PATIENT_GENDER}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PATLIST_HEADER_CREATEDATE" class="col"
			[ngClass]="{'active': activeColumn === fields.patCreatedDate }">
			<sortable-header [name]="fields.patCreatedDate" (sort)="onSort($event)">
				{{labels.PATIENT_TABLE_COMPONENT_PATIENT_CREATION_DATE}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_PATLIST_HEADER_ACTIONS" class="col no-link" [ngClass]="{'col-8': isTlhexUser}">
			{{labels.PATIENT_TABLE_COMPONENT_ACTIONS}}
		</div>

	</div>

</div>

<!-- Single line -->
<div class="panel panel-tr" *ngFor="let pat of patientList">

	<div class="row no-gutters">

		<div data-testid="SUBSYS2_PATLIST_CELL_PUBLISHED" class="col short-col" *ngIf="isTlhexUser">
			<img class="iwrench-icon" alt="iwrench icon" *ngIf="pat.patientHasIWrenchPublished; else noIWrench" />
			<ng-template #noIWrench>
				<img class="myhexplan-icon" alt="published icon" *ngIf="pat.patientHasPublished" />
			</ng-template>
		</div>
		<div data-testid="SUBSYS2_PATLIST_CELL_PATID" class="col">
			<a href="" onclick="return false;" (click)="editPatient(pat.patientId)">{{pat.patientNumber}}</a>
		</div>
		<div data-testid="SUBSYS2_PATLIST_CELL_INITIALS" class="col">
			{{pat.patientInitials}}
		</div>
		<div data-testid="SUBSYS2_PATLIST_CELL_GENDER" class="col">
			<div class="user-holder" [ngClass]="genderClass(pat.patientGender)">
				<span>{{pat.patientGender}}</span>
			</div>
		</div>
		<div data-testid="SUBSYS2_PATLIST_CELL_CREATEDATE" class="col">
			{{pat.patientCreationDate | convertDate }}
		</div>
		<div data-testid="SUBSYS2_PATLIST_CELL_ACTIONS" class="col" [ngClass]="{'col-8 text-left': isTlhexUser}">
			<patient-action [item]="pat"></patient-action>
		</div>

	</div>

</div>


<!-- Pagination -->
<pagination></pagination>