import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription, filter } from 'rxjs';
import { BoneViewEnum, LanguageService } from '../../core';
import { Attachment } from '../../models';
import { AttachmentService, PlanFormService, UserService, isCheckUpImageValid, isImageCompatible } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
 * Plan image section component.
 */
@Component({
	selector: 'plan-image',
	templateUrl: './plan-image.component.html'
})
export class PlanImageComponent extends BaseComponent implements OnInit {

	@Input() dropApId: string;
	@Input() dropLateralId: string;

	form: RxFormGroup;
	apImage: Attachment;
	isApVisible: boolean = true;
	ltImage: Attachment;
	isLateralVisible: boolean = true;

	noProductsEnabled: boolean;

	private _apImageSubscription$: Subscription;
	private _ltImageSubscription$: Subscription;

	constructor(
		private readonly langSrv: LanguageService,
		private readonly attachSrv: AttachmentService,
		private readonly modalSrv: NgbModal,
		private readonly planFormSrv: PlanFormService,
		private readonly usrSrv: UserService
	) {
		super(langSrv);
	}

	get isBoxDisabled(): boolean { return this.planFormSrv.isImagesBoxDisabled; }
	get productList(): string[] { return this.planFormSrv.productList }

	ngOnInit() {
		this.usrSrv.isOnlyPlatformDemo().subscribe(res => this.noProductsEnabled = res);
		this.planFormSrv.getForm().subscribe(form => this.form = form);
		this._apImageSubscription$ = this.attachSrv.getApImage().pipe(filter(img => !!img)).subscribe(image => this.apImage = image);
		this._ltImageSubscription$ = this.attachSrv.getLtImage().pipe(filter(img => !!img)).subscribe(image => this.ltImage = image);
	}

	/**
	 * Predicate function that only allows valid image to be dropped into AP box.
	 */
	apPredicate(otherImg: Attachment) {
		const { isCheckUp, checkUpBoneType, checkUpSide } = this.attachSrv.checkUpData;
		return function (drag: CdkDrag<Attachment>, drop: CdkDropList) {
			return isCheckUp ? isCheckUpImageValid(drag.data, checkUpBoneType, checkUpSide, BoneViewEnum.AP, otherImg) : isImageCompatible(drag.data, BoneViewEnum.AP, otherImg);
		};
	}

	/**
	* Set ap image on drop event
	*/
	dropApImage(event: CdkDragDrop<Attachment[]>): void {
		if (event.previousContainer != event.container && event.isPointerOverContainer) {
			const img = event.item.data as Attachment;
			if (this.isCheckupRingWarning(img)) {
				this.openCheckupRingWarningModal(() => this.attachSrv.setApImageId(img?.id));
			} else {
				this.attachSrv.setApImageId(img?.id);
			}
		}
		this.isApVisible = true;
	}

	/**
	* Remove ap preview on enter event
	*/
	onApEnter(ev: any): void {
		this.isApVisible = false;
	}

	/**
	* Add ap preview on exit event
	*/
	onApExit(ev: any): void {
		this.isApVisible = true;
	}

	/**
	 * Predicate function that only allows valid image to be dropped into LAT box.
	 */
	latPredicate(otherImg: Attachment) {
		const { isCheckUp, checkUpBoneType, checkUpSide } = this.attachSrv.checkUpData;
		return function (drag: CdkDrag<Attachment>, drop: CdkDropList) {
			return isCheckUp ? isCheckUpImageValid(drag.data, checkUpBoneType, checkUpSide, BoneViewEnum.LAT, otherImg) : isImageCompatible(drag.data, BoneViewEnum.LAT, otherImg);
		};
	}

	/**
	* Set lateral image on drop event
	*/
	dropLateralImage(event: CdkDragDrop<Attachment[]>): void {
		if (event.previousContainer != event.container && event.isPointerOverContainer) {
			const img = event.item.data as Attachment;
			if (this.isCheckupRingWarning(img)) {
				this.openCheckupRingWarningModal(() => this.attachSrv.setLtImageId(img?.id));
			} else {
				this.attachSrv.setLtImageId(img?.id);
			}
		}
		this.isLateralVisible = true;
	}

	/**
	* Remove lateral preview on enter event
	*/
	onLateralEnter(ev: any): void {
		this.isLateralVisible = false;
	}

	/**
	* Add lateral preview on exit event
	*/
	onLateralExit(ev: any): void {
		this.isLateralVisible = true;
	}

	/**
	* Reset ap image
	*/
	removeApImage(): void {
		this.apImage = null;
		this.attachSrv.setApImageId(null);
	}

	/**
	* Reset lateral image
	*/
	removeLateralImage(): void {
		this.ltImage = null;
		this.attachSrv.setLtImageId(null);
	}

	/**
	 * Check if calibration rings are equal to checkup rings.
	 */
	private isCheckupRingWarning(img: Attachment): boolean {
		const ckUp = this.attachSrv.checkUpData;
		return !!img && ckUp.isCheckUp && ((!!img.ringProximalId && img.ringProximalId != ckUp.checkUpProxRingId) || (!!img.ringDistalId && img.ringDistalId !== ckUp.checkUpDistRingId));
	}

	/**
	* Open confirmation modal for checkup rings mismatch.
	*/
	private openCheckupRingWarningModal(confirmFunction: Function) {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, { centered: true, backdrop: 'static' });
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['IMAGE_LOADER_COMPONENT_CHECKUP_MODAL_TITLE'],
				message: this.labels['IMAGE_LOADER_COMPONENT_CHECKUP_MODAL_MESSAGE'],
				cancelBtn: this.labels['IMAGE_LOADER_COMPONENT_CHECKUP_MODAL_NO'],
				confirmBtn: this.labels['IMAGE_LOADER_COMPONENT_CHECKUP_MODAL_YES']
			};
			modalRef.result.then(() => confirmFunction.call(this)).catch(error => false);
		}
	}


	ngOnDestroy() {
		this._apImageSubscription$?.unsubscribe();
		this._ltImageSubscription$?.unsubscribe();
	}
}
