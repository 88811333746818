import { Pipe, PipeTransform } from '@angular/core';
import { BoneTypeEnum, BoneViewEnum, LanguageService } from '../../core';


/**
 * Pipe to convert Bone View enum to display value
 */
@Pipe({
	name: 'boneViewLabel'
})
export class BoneViewLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(boneView: BoneViewEnum, boneType: BoneTypeEnum): string {
		if (boneView === BoneViewEnum.AP && (boneType === BoneTypeEnum.Forefoot || boneType === BoneTypeEnum.Hindfoot)) {
			return this.langSrv.labels.BONE_VIEW_DORSAL_LABEL;
		}
		return boneView;
	}

}
