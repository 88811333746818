import { CalibrationEllipse, CalibrationMode, CaseModel, ConstsBase, LayersBase, MainBase, MeasuresController, MechanicalAxisAP, PrintUtilsBase, SaveHandlerBase, SceneBase, Tools, ToolsInitBase, ViewType } from '@ortho-next/nextray-core';
import { BindedModel, bindedModel } from './BindedModel';
import { Bridge } from './Bridge';
import { Context } from './Context';
import { DirectionalLight } from '@ortho-next/three-base/three.js/build/three.module';
import { AppModel } from './AppModel';
import { environment } from 'src/environments/environment';

class ToolsAP extends Tools { //lateral is not present, so reuse AP
	viewType = ViewType.AP;
	mechanicalAxis: MechanicalAxisAP;
	contralateralMechanicalAxis: MechanicalAxisAP;
};

export class Main extends MainBase {
	public override model: AppModel;
	public override bridge: Bridge;
	protected override _context: Context;

	constructor(canvasContainer: HTMLDivElement) {
		super(canvasContainer, {
			appModel: AppModel,
			bindedModel: new BindedModel(),
			bridge: Bridge,
			contextProto: Context,
			measuresController: MeasuresController,
			printUtilsProto: PrintUtilsBase,
			saveHandlerProto: SaveHandlerBase,
			sceneProto: SceneBase,
			toolsAPProto: ToolsAP,
			toolsLTProto: ToolsAP
		});
		
		ConstsBase.cdnUrl = environment.cdnUrl;
		this.setView(ViewType.AP);

		const dirLight = new DirectionalLight(0x555555, 0.7);
		dirLight.position.set(0, 0, 500);
		this.activeScene.add(dirLight);

		this._context.addEventListener('setModel', (event) => {
			if (this.activeTools.plane) {
				this.resetModel(event.args);
			} else {
				this.loadScenes(event.args, ToolsInitBase, LayersBase);
			}
		});

		this._context.addEventListener('calibrate', (event) => {
			const calibrationTool = this.activeTools.calibrationTool;
			if (calibrationTool.mode === CalibrationMode.ellipse) {
				const ellipse = calibrationTool.tool as CalibrationEllipse;
				this.model.calibrationEllipsePoints = [ellipse.coneA.position, ellipse.coneB.position, ellipse.coneC.position, ellipse.rotationPoint.position];
				ellipse.ring.updateMatrix();
				this.model.ringMatrix = ellipse.ring.matrix;
				this.model.angle = ellipse.ring.angle;
				this.model.frontal = ellipse.ring.frontal;
				this.model.axial = ellipse.ring.axial;
			}
		});
		
		this._context.addEventListener('setRingCalibrationData', (event) => {
			const calibrationTool = this.activeTools.calibrationTool;
			if (calibrationTool.mode === CalibrationMode.ellipse) {
				const ellipse = calibrationTool.tool as CalibrationEllipse;
				ellipse.setRingCalibrationData(event.args);
			}
		});

		this._context.addEventListener('setRing', (event) => {
			const calibrationTool = this.activeTools.calibrationTool;
			if (calibrationTool.mode === CalibrationMode.ellipse) {
				const ellipse = calibrationTool.tool as CalibrationEllipse;
				ellipse.setRing(event.args?.ringModel, event.args?.oppositeRingType);
			}
		});

	}

	private resetModel(model: CaseModel): void {
		this.caseModel = model;
		this.activeTools.plane.loadImage(model.imgAP, () => {
			this.resetScene();
			this.applyInitialFlipAndScaleFactor();
			this._context.handle('fitToView');
		});
	}

	private resetScene(): void {
		this.activeTools.plane.reset();
		bindedModel.calibratingAP = false;
		bindedModel.scaleFactorAP = 1;
	}

}
