import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { finalize } from 'rxjs';
import { LanguageService, ProductTypeEnum, ToastService } from '../../core';
import { PlanSendForm } from '../../models';
import { SendService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Modal component to send plan
 */
@Component({
	selector: 'plan-send-modal',
	templateUrl: './plan-send-modal.component.html',
	styles: []
})
export class PlanSendModalComponent extends BaseComponent implements OnInit {

	sendForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(PlanSendForm);

	planGuid: string;
	userGuid: string;
	planId: string;
	isSaving: boolean;

	constructor(
		private langSrv: LanguageService,
		public activeModal: NgbActiveModal,
		private formBuilder: RxFormBuilder,
		private sendSrv: SendService,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.sendSrv.getPlan(this.planGuid).subscribe(plan => this.planId = plan.number);
		this.initForm();
		this.initSendForm();
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.labels,
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	private initSendForm(): void {
		this.sendForm = <RxFormGroup>this.formBuilder.formGroup(PlanSendForm, { planGuid: this.planGuid, userGuid: this.userGuid });
	}

	private get userName() {
		return this.sendForm.controls.userName;
	}
	private get userNotExisting() {
		return this.sendForm.controls.userNotExisting;
	}
	private get userNotEligible() {
		return this.sendForm.controls.userNotEligible;
	}

	/**
	* Trim username input field
	*/
	trimUserName(): void {
		if (this.userName.value) {
			this.userName.setValue(this.userName.value.trim());
		}
	}

	/**
	* Reset username validation
	*/
	resetUserNameValidation() {
		if (this.userNotExisting.value) {
			this.userNotExisting.setValue(false);
			this.userName.setValue(this.userName.value);
		}
		if (this.userNotEligible.value) {
			this.userNotEligible.setValue(false);
			this.userName.setValue(this.userName.value);
		}
	}

	/**
	* Submit form and handle response messages
	*/
	confirm(): void {
		if (this.sendForm.valid && !this.isSaving) {
			this.isSaving = true;
			this.sendSrv.sendForm(this.sendForm.value).pipe(
				finalize(() => this.isSaving = false)
			).subscribe({
				next: res => {
					this.toastSrv.showSuccess(this.labels['PLAN_SEND_MODAL_COMPONENT_SUCCESS_MESSAGE']);
					this.activeModal.close();
				},
				error: err => {
					this.toastSrv.showError(this.labels['PLAN_SEND_MODAL_COMPONENT_ERROR_MESSAGE']);
					this.handleSendError(err);
				}
			});
		}
	}

	private handleSendError(err: Error): void {
		switch (err.message) {
			case 'UserNotFound': {
				this.userNotExisting.setValue(true);
				this.userName.setValue(this.userName.value);
				break;
			}
			case 'UserNotJpsProd': {
				this.setNotEligibleValidation(ProductTypeEnum.JPS);
				break;
			}
			case 'UserNotFitboneProd': {
				this.setNotEligibleValidation(ProductTypeEnum.Fitbone);
				break;
			}
			case 'UserNotTlhexProd': {
				this.setNotEligibleValidation(ProductTypeEnum.TLHex);
				break;
			}
		}
	}

	private setNotEligibleValidation(module: string): void {
		this.userNotEligible.setValue(true);
		this.userName.setValue(this.userName.value);
		const errorMessage: string = this.userName['_errorMessage'];
		if (errorMessage && errorMessage.includes('<Module>')) {
			this.userName['_errorMessage'] = errorMessage.replace('<Module>', module);
		}
	}

}
