import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RingCalibrationData, ViewType } from '@ortho-next/nextray-core';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, BoneTypeEnum, BoneViewEnum, LanguageService, LoaderService, ToastService } from '../../core';
import { ImageCalibrationForm, ImageCategorizationForm, ImageItem, PlanForm, RingCalibrationResult } from '../../models';
import { AiService, AttachmentService, CanvasService, PlanFormService, PlanService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';
import { ImageCalibrationComponent } from '../image-calibration';



/**
 * Component to categorize a list of images
 */
@Component({
	selector: 'image-categorization',
	templateUrl: './image-categorization.component.html'
})
export class ImageCategorizationComponent extends BaseComponent implements OnInit {

	@Input() edit: boolean = false;
	@Input() imageList: ImageItem[];

	@ViewChild(ImageCalibrationComponent) calibrationCmp!: ImageCalibrationComponent;

	categorizationForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(ImageCategorizationForm);
	calibrationForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(ImageCalibrationForm);

	// calibration data
	circleDetection: boolean;
	isRingsToolVisible: boolean;
	isFoot: boolean;
	ringCalibData: RingCalibrationData;

	counter: string = '0/0';
	confirmBtn: string = this.labels['IMAGE_CATEGORIZATION_COMPONENT_NEXT_BTN'];

	private _currentImage: ImageItem;
	private _index: number = -1;
	private _length: number = 0;
	private _isSaving: boolean;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private attachSrv: AttachmentService,
		private canvasSrv: CanvasService,
		private toastSrv: ToastService,
		private planSrv: PlanService,
		private aiSrv: AiService,
		private loaderSrv: LoaderService,
		private planFormSrv: PlanFormService,
		private userSrv: UserService,
		private router: Router,
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initForm();
		this.canvasSrv.init();
		this._length = this.imageList?.length;
		this._length && this.update();
		this.updateRingCalibrationData();
		const isPlanPage = this.router.url.startsWith("/plan/");
		if (isPlanPage) {
			this.userSrv.isTlhex().pipe(
				filter(isTlhex => !!isTlhex),
				switchMap(() => this.planFormSrv.getForm())
			).subscribe(form => this.isRingsToolVisible = (form.value as PlanForm).postOperative);
		}
	}

	private get _calibValue(): ImageCalibrationForm {
		return this.calibrationForm.value;
	}

	private get _categCtrl(): { [key in keyof ImageCategorizationForm]: AbstractControl } {
		return this.categorizationForm.controls as { [key in keyof ImageCategorizationForm]: AbstractControl };
	}

	private get _categValue(): ImageCategorizationForm {
		return this.categorizationForm.value;
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.labels,
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	/**
	 * Check if image calibration workflow is visible.
	 */
	get isCalibrationVisible(): boolean {
		return this.canvasSrv.isCalibrationVisible;
	}

	/**
	 * Check if image calibration workflow is disabled.
	 */
	get isCalibrationDisabled(): boolean {
		return this._isSaving || this._isCalibrationDataInvalid;
	}

	private get _isCalibrationDataInvalid(): boolean {
		return this._categCtrl.boneType.invalid || this._categCtrl.boneView.invalid || this._categCtrl.boneSide.invalid;
	}

	get isConfirmBtnDisabled(): boolean {
		return this._isSaving || this.categorizationForm.invalid || this.isCalibrationVisible;
	}

	/**
	* Open confirmation modal before close garage modal
	*/
	openConfirmModal() {
		const modalRef: NgbModalRef = this.modalService.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.labels['IMAGE_GARAGE_COMPONENT_CLOSE_MODAL_TITLE'],
			message: this.labels['IMAGE_GARAGE_COMPONENT_CLOSE_MODAL_MESSAGE'],
			cancelBtn: this.labels['IMAGE_GARAGE_COMPONENT_CLOSE_MODAL_NO'],
			confirmBtn: this.labels['IMAGE_GARAGE_COMPONENT_CLOSE_MODAL_YES']
		};
		modalRef.result.then(() => this.activeModal.dismiss()).catch(error => false);
	}

	/**
	 * Update scale factor.
	 */
	updateScaleFactor(): void {
		this._categCtrl.scaleFactor.setValue(this.canvasSrv.scaleFactor);
		this.updateCalibrationData();
		this.canvasSrv.isCalibrationVisible = false;
	}

	private updateCalibrationData(): void {
		const isRingCalib = this._calibValue.calibrationTool === 'rings';
		this._categCtrl.isRingCalibration.setValue(isRingCalib);
		if (isRingCalib) {
			const ringCalib: RingCalibrationResult = {
				referenceType: this.ringCalibData.refType,
				proxRingId: this._calibValue.proximalRing?.id,
				distalRingId: this._calibValue.distalRing?.id,
				ellipsePoints: this.canvasSrv.calibrationEllipsePoints,
				ringMatrix: this.canvasSrv.ringMatrix,
				angle: this.canvasSrv.angle,
				frontal: this.canvasSrv.frontal,
				axial: this.canvasSrv.axial
			};
			this._categCtrl.ringCalibration.setValue(ringCalib);
		} else {
			this._categCtrl.ringCalibration.reset(null);
		}
	}

	/**
	 * Update calibration workflow.
	 */
	updateCalibration(): void {
		if (this._categValue.ringCalibration || this._calibValue.calibrationTool === 'rings') {
			this.resetRingCalibration();
		}
		this.updateRingCalibrationData();
		this.handleCircleDetection();
	}

	private resetRingCalibration(): void {
		this._categCtrl.scaleFactor.reset();
		this._categCtrl.isRingCalibration.reset(false);
		this._categCtrl.ringCalibration.reset(null);
		this.updateCanvasModel();
		this.calibrationForm.reset();
		this.canvasSrv.isCalibrationVisible = true;
	}

	private updateRingCalibrationData(): void {
		this.isFoot = this._categValue.boneType === BoneTypeEnum.Forefoot || this._categValue.boneType === BoneTypeEnum.Hindfoot;
		if (!this._isCalibrationDataInvalid) {
			const viewType: ViewType = this._categValue.boneView === BoneViewEnum.LAT ? ViewType.LT : ViewType.AP;
			this.ringCalibData = {
				viewType,
				boneType: this._categValue.boneType,
				refType: this.planFormSrv.referenceType,
				side: this._categValue.boneSide
			};
		}
	}

	/**
	 * Set next image form
	 */
	confirm(): void {
		if (!this.isConfirmBtnDisabled) {
			const item = this.attachSrv.updateImageItem(this._currentImage, this._categValue);
			if (this.edit) {
				this.handleEdit(item);
			} else {
				this.handleUpload(item);
			}
		}
	}

	private handleEdit(item: ImageItem): void {
		this._isSaving = true;
		this.planSrv.deletePlansByImageId(item.id).pipe(
			map(res => this.handlePlanDeleteResponse(res)),
			switchMap(() => this.attachSrv.editImage(item)),
			finalize(() => {
				this._isSaving = false;
				this.activeModal.close();
				this.attachSrv.updateAttachmentList(item.patientId, this.planFormSrv.userId);
			})
		).subscribe({
			next: () => this.toastSrv.showSuccess(this.labels['IMAGE_CATEGORIZATION_COMPONENT_EDIT_SUCCESS']),
			error: () => this.toastSrv.showWarning(this.labels['IMAGE_CATEGORIZATION_COMPONENT_EDIT_ERROR'])
		});
	}

	private handlePlanDeleteResponse(res: ApiResponse<any>): ApiResponse<any> {
		if (res.statusCode === ApiResponseStatusCode.PublishedRevokedCaseError) {
			this.toastSrv.showWarning(this.labels['IMAGE_CATEGORIZATION_COMPONENT_EDIT_PUBLISHED_ERROR']);
			throw new Error('PublishedRevokedCaseError');
		};
		return res;
	}

	private handleUpload(item: ImageItem): void {
		this._isSaving = true;
		this.attachSrv.uploadImage(item).pipe(
			finalize(() => {
				this._isSaving = false;
				this.update();
			})
		).subscribe({
			next: () => this.toastSrv.showSuccess(this.labels['IMAGE_CATEGORIZATION_COMPONENT_UPLOAD_SUCCESS']),
			error: () => this.toastSrv.showWarning(this.labels['IMAGE_CATEGORIZATION_COMPONENT_UPLOAD_ERROR'])
		});
	}

	private update(): void {
		if (this._index < this._length - 1) {
			this._index = this._index + 1;
			this._currentImage = this.imageList[this._index];
			this.updateCanvasModel();
			this.updateForms(this._currentImage);
			this.counter = this._length > 1 ? `${this._index + 1}/${this._length || 0}` : '';
			this.confirmBtn = this.btnLabel;

			// reset calibration
			this.canvasSrv.isCalibrationVisible = !this.edit;
			this.circleDetection = false;
			this.ringCalibData = null;
		} else {
			this.activeModal.close();
		}
	}

	private updateCanvasModel(): void {
		if (this.edit) {
			this.canvasSrv.setModel(this._currentImage);
		} else {
			this.canvasSrv.setImage(this._currentImage.file);
		}
	}

	private updateForms(item: ImageItem): void {
		this.categorizationForm.resetForm();
		this.categorizationForm.reset(this.attachSrv.categorizationFormMapper(item));
		this.categorizationForm.markAsUntouched();
	}

	private handleCircleDetection(): void {
		if (!this.edit && !(window as any).noCircleDetection && !this.circleDetection && !this._isCalibrationDataInvalid) {
			this.circleDetection = true;
			this.loaderSrv.show(true);
			this.aiSrv.circleDetection(this._currentImage.file).pipe(
				finalize(() => this.loaderSrv.hide()),
				filter(res => !!res?.coordinates),
				map(res => res.coordinates)
			).subscribe(coordinates => this.calibrationCmp?.setCircleByPoints(coordinates));
		}
	}

	private get btnLabel(): string {
		if (this.edit) {
			return this.labels['IMAGE_CATEGORIZATION_COMPONENT_EDIT_BTN'];
		} else {
			return this._index !== this._length - 1 ? this.labels['IMAGE_CATEGORIZATION_COMPONENT_NEXT_BTN'] : this.labels['IMAGE_CATEGORIZATION_COMPONENT_UPLOAD_BTN'];
		}
	}

}
