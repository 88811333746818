import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../services';
import { SharedModule } from '../shared';
import { CalendarDayComponent } from './calendar-day';
import { CalendarEventsComponent } from './calendar-events';
import { DashboardCalendarComponent } from './dashboard-calendar';
import { DashboardEventsComponent } from './dashboard-events';
import { DashboardComponent } from './dashboard.component';



@NgModule({
	declarations: [
		DashboardComponent,
		DashboardCalendarComponent,
		CalendarEventsComponent,
		DashboardEventsComponent,
		CalendarDayComponent
	],
	imports: [
		CommonModule,
		NgbDatepickerModule,
		SharedModule,
		NgbTooltipModule,
		NgbAccordionModule
	],
	providers: [
		DashboardService
	]
})
export class DashboardModule { }
