import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ITlhexRepository } from './Itlhex.repository';
import { ApiResponse, MountingParameters, RingSize, StrutEvent } from './models';


@Injectable()
export class TlhexRepository implements ITlhexRepository {

	constructor(private httpClient: HttpClient) { }

	getStrutsEvents(from: Date, to: Date): Observable<ApiResponse<StrutEvent[]>> {
		let params = new HttpParams().set('from', from.toDateString()).set('to', to.toDateString());
		return this.httpClient.get<ApiResponse<StrutEvent[]>>(environment.domainTlhexAPI + `/api/Report/StrutsEvents`, { params });
	}

	getRingSizes(): Observable<ApiResponse<RingSize[]>> {
		return this.httpClient.get<ApiResponse<RingSize[]>>(environment.domainTlhexAPI + `/api/Products/RingSizes`);
	}

	getMountingParameters(caseId: string, userId: string): Observable<ApiResponse<MountingParameters>> {
		let params = new HttpParams().set('caseId', caseId).set('userId', userId);
		return this.httpClient.get<ApiResponse<MountingParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/MountingParameters`, { params });
	}
}
