import { Pipe, PipeTransform } from '@angular/core';
import { AnatomicalSideEnum, LanguageService } from '../../core';


/**
 * Pipe to convert Anatomical Side enum to display value.
 */
@Pipe({
	name: 'anatomicalSideLabel'
})
export class AnatomicalSideLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(side: AnatomicalSideEnum): string {
		switch (side) {
			case AnatomicalSideEnum.Left: return this.langSrv.labels.ANATOMICAL_SIDE_LEFT;
			case AnatomicalSideEnum.Right: return this.langSrv.labels.ANATOMICAL_SIDE_RIGHT;
			default: return side;
		}
	}

}
