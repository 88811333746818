import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, DICTIONARY_NAME_USSTATE, ProductTypeEnum, SURGEON, US_CODE, UserLastSignIn, UserProfile, UserProfileCountry, UserRepository, convertToLocalDate } from '../core';
import { ProfileForm } from '../models';


/**
 * This service handles user profile
 */
@Injectable()
export class UserService {

	private _currentUser$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
	private _usStatesList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

	private readonly CUSTOMER_CARE: string = 'CustomerCare';

	constructor(private userRepo: UserRepository) { }


	/**
	* Get first name and last name of user profile
	*/
	getUserName(): Observable<{ firstName: string, lastName: string }> {
		return this.getCurrentUser().pipe(map(user => ({ firstName: user.firstName, lastName: user.lastName })));
	}

	/**
	* Get country code of user profile
	*/
	getUserCountry(): Observable<string> {
		return this.getCurrentUser().pipe(filter(user => !!user), map(user => user.country.code));
	}

	/**
	* Get language of user profile.
	*/
	getUserLanguage(): Observable<string> {
		return this.getCurrentUser().pipe(filter(user => !!user), map(user => user.language));
	}

	/**
	* Load user profile
	*/
	loadUser(): Observable<UserProfile> {
		return this.getUserProfile().pipe(first(user => !!user), tap(user => this._currentUser$.next(user)));
	}

	/**
	* Get current user profile
	*/
	getCurrentUser(): Observable<UserProfile> {
		return this._currentUser$.asObservable().pipe(first(user => !!user));
	}

	/**
	* Get United States list
	*/
	getUsStatesList(): Observable<string[]> {
		if (this._usStatesList$.getValue()) {
			return this._usStatesList$.asObservable().pipe(first());
		}
		return this.getUnitedStates().pipe(tap(list => this._usStatesList$.next(list)));
	}

	/**
	* Check if user has to accept legal documents
	*/
	hasToAcceptLegalDocs(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user),
			map(user => user.hasToAcceptLegalDocs)
		);
	}

	/**
	* Check if user is customer care
	*/
	isCustomerCare(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.roles),
			map(user => user.roles.includes(this.CUSTOMER_CARE))
		);
	}

	/**
	 * Check if input user is different than customer care.
	 */
	isCustomerCareOtherUser(usrId: string): Observable<boolean> {
		return this.isCustomerCare().pipe(
			map(isCC => isCC && this._currentUser$.value.id != usrId)
		);
	}

	/**
	* Check if user has Platform Demo product
	*/
	isPlatformDemo(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.Platform && p.demo))
		);
	}

	/**
	* Check if user has ONLY Platform Demo product
	*/
	isOnlyPlatformDemo(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.products),
			map(user => user.products.map(p => p.type)),
			map(types => types.length === 1 && types[0] === ProductTypeEnum.Platform)
		);
	}

	/**
	* Check if user has Tlhex product
	*/
	isTlhex(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.TLHex))
		);
	}

	/**
	* Check if user has MyHexPlan product
	*/
	isMyhexplan(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.MyHexPlan))
		);
	}

	/**
	* Check if user has Iwrench product
	*/
	isIwrench(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.Iwrench))
		);
	}

	/**
	* Get user guid by user name
	*/
	getUserGuid(username: string): Observable<string> {
		return this.userRepo.getUserGuid(username).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	getUnitedStates(): Observable<string[]> {
		return this.userRepo.getDictionaries(DICTIONARY_NAME_USSTATE).pipe(
			map(res => this.handleApiResponse(res)),
			filter(list => list && list.length === 1),
			map(list => list[0].values),
			map(list => list.map(state => state.otherInfo))
		);
	}

	/**
	* Get edit form model from user profile
	*/
	getProfileForm(): Observable<ProfileForm> {
		return this.getUserProfile().pipe(map(profile => this.profileFormMapper(profile)));
	}

	/**
	* Update user profile by form model
	*/
	editProfileForm(form: ProfileForm): Observable<any> {
		const oldProfile: UserProfile = this._currentUser$.value;
		const newProfile: UserProfile = this.updateUserProfile(oldProfile, form);
		return this.userRepo.editUser(newProfile).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Get user last sign in. 
	 */
	getLastSignIn(): Observable<UserLastSignIn> {
		return this.getCurrentUser().pipe(
			switchMap(user => this.userRepo.getLastSignIn(user.id)),
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Get user languages.
	 */
	getLanguages(): Observable<string[]> {
		return this.userRepo.getLanguages().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Change user language.
	 */
	changeLanguage(lang: string): Observable<void> {
		return this.userRepo.changeLanguage(lang).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	private getUserProfile(): Observable<UserProfile> {
		return this.userRepo.getUserProfile().pipe(
			map(res => this.handleApiResponse(res))
		);
	}


	private profileFormMapper = (profile: UserProfile): ProfileForm => {
		if (!profile) return null;
		return {
			firstName: profile.firstName,
			lastName: profile.lastName,
			email: profile.userName,
			dateOfBirth: new Date(profile.birthDate),
			dateOfBirthFormat: convertToLocalDate(profile.birthDate),
			country: profile.country,
			hospital: this.isSurgeon(profile.roleInfo) ? profile.hospitalOrCompany : null,
			company: !this.isSurgeon(profile.roleInfo) ? decodeURI(profile.hospitalOrCompany) : null,
			distributor: profile.distributor,
			city: profile.city,
			address: decodeURI(profile.address),
			postalCode: profile.postalCode,
			officePhone: profile.officePhone,
			mobilePhone: profile.mobilePhone,
			stateProvince: !this.isUnitedStates(profile.country) ? profile.stateProvince : null,
			usStateProvince: this.isUnitedStates(profile.country) ? profile.stateProvince : null,
			roleInfo: profile.roleInfo,
			practice: profile.practice
		};
	}

	private updateUserProfile(profile: UserProfile, form: ProfileForm): UserProfile {
		if (!form) return profile;
		return {
			...profile, ...{
				birthDate: form.dateOfBirth,
				hospitalOrCompany: this.isSurgeon(profile.roleInfo) ? form.hospital : encodeURI(form.company),
				distributor: this.isSurgeon(profile.roleInfo) ? form.distributor : null,
				stateProvince: this.isUnitedStates(profile.country) ? form.usStateProvince : form.stateProvince,
				city: form.city,
				address: encodeURI(form.address),
				postalCode: form.postalCode,
				officePhone: form.officePhone,
				mobilePhone: form.mobilePhone
			}
		};
	}

	private isUnitedStates(country: UserProfileCountry): boolean {
		return country && country.code === US_CODE;
	}

	private isSurgeon(role: string): boolean {
		return role && role === SURGEON;
	}

	private handleApiResponse<T>(response: ApiResponse<T>): T {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error("Generic error");
		}
	}

}
